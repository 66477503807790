import { useState, useEffect } from 'react';
import useConnector from './Connectors';
import { useWeb3React } from '@web3-react/core';
import WalletModal from './WalletModal/WalletModal';
import { useDispatch, useSelector } from 'react-redux';
import { setProvider } from '../../store/web3/provider';
import Disconnect from './disconnetModal/disconnect';
import Web3 from 'web3';
import { walletCredentials } from '../../store/web3/connectedUserInfo';
import { setChainErrorMsg } from '../../store/web3/selectedNetwork';
import {
  setChainID,
  setModalNetwork,
  setAddress,
} from '../../store/web3/walletSlice';
import SwitchChainBtn from './SwitchChain/SwitchChainButton';
import { SupportedWallets } from './constants/wallet';
import { numberToHexadecimal, getSampleChain } from './constants/chain';
import { setWalletType } from './wallet';
import { walletConnectV2 } from './walletConnect';
import { URI_AVAILABLE } from '@web3-react/walletconnect-v2';
import { hooks } from './walletConnect';

import { toast } from 'react-toastify';
import cx from 'classnames';
import { Button, Text } from 'components';
import styles from './MultiWallet.module.scss';
import { walletIcon, connectWalletBtn } from 'assets/images';

const { useAccounts, useChainId } = hooks;

let previousAccountDetect = new Date();
var chainIdHook: any;

const ToastShow = (type: any, message: any) => {
  if (type === 'info') {
    return toast.info(message);
  } else if (type === 'error') {
    return toast.error(message);
  } else if (type === 'success') {
    return toast.success(message);
  } else if (type === 'warn') {
    toast.warn(message);
  }
};

const MultiWallet = () => {
  const dispatch = useDispatch();
  const { connectedWallet, userConnected } = useSelector(
    (state: any) => state.user
  );

  const { provider, address: addresses } = useSelector(
    (state: any) => state.providerReducer
  );

  const { chainId: RdChainId } = useSelector((state: any) => state.wallet);
  // const addresses = useSelector(
  //   (state: any) => state?.user?.dataUser?.userWallet?.address
  // );

  const [modalOpen, setModalOpen] = useState(false);
  const [walletName, setWalletName] = useState('');
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);

  const { connectorType } = useConnector();

  const {
    provider: library,
    account,
    chainId,
    connector,
  }: any = useWeb3React();
  const hookAcc = useAccounts();
  chainIdHook = useChainId();

  const userSplit = addresses?.slice(0, 4);
  const userName = addresses?.slice(addresses.length - 4, addresses.length);
  const userAddress = userSplit?.concat('...', userName);

  const switchChain = async () => {
    try {
      await provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: numberToHexadecimal(RdChainId),
          },
        ],
      });
    } catch (switchError: any) {
      if (
        switchError.code === 4902 ||
        String(switchError).includes('wallet_addEthereumChain')
      ) {
        try {
          await provider?.request({
            method: 'wallet_addEthereumChain',
            params: [getSampleChain(RdChainId)],
          });
          switchChain();
        } catch (err: any) {
          if (
            err.code === 4001 ||
            String(err).includes('User rejected the request')
          ) {
            dispatch(setChainID(chainId));
          }
        }
      } else if (
        switchError.code === 4001 ||
        String(switchError).includes('User rejected the request')
      ) {
        dispatch(setChainID(chainId));
      }
    }
  };

  useEffect(() => {
    walletConnectV2.events.on(URI_AVAILABLE, (uri: string) => {
      console.log(`uri: ${uri}`);
    });
    walletConnectV2.connectEagerly().catch((error) => {
      console.debug('Failed to connect eagerly to walletconnect', error);
    });
  }, []);

  useEffect(() => {
    if (userConnected && chainId && RdChainId && RdChainId !== chainId) {
      switchChain();
    }
  }, [userConnected]);

  if (provider && Object.keys(provider).length > 0 && userConnected) {
    provider?.on('chainChanged', (data: string) => {
      dispatch(setChainID(parseInt(data)));
    });

    provider?.on('accountsChanged', (data: any) => {
      if (userConnected && data && data.length === 0) {
        dispatch(
          walletCredentials({
            userConnected: false,
            connectedWallet: '',
          })
        );
        dispatch(setAddress(undefined));
        dispatch(
          setProvider({
            provider: {},
            address: '',
          })
        );
      }
    });
  }

  useEffect(() => {
    if (
      account &&
      userConnected &&
      account.toLowerCase() !== addresses.toLowerCase() &&
      previousAccountDetect.getSeconds() !== new Date().getSeconds() &&
      connectedWallet === SupportedWallets.ethereum
    ) {
      previousAccountDetect = new Date();
      getAuthToken(account, connectedWallet);
    }
  }, [account]);

  useEffect(() => {
    if (hookAcc && hookAcc.length > 0) {
      const lastActiveAcc = hookAcc[hookAcc.length - 1];
      if (
        lastActiveAcc &&
        userConnected &&
        lastActiveAcc.toLowerCase() !== addresses.toLowerCase() &&
        previousAccountDetect.getSeconds() !== new Date().getSeconds()
      ) {
        previousAccountDetect = new Date();
        getAuthToken(lastActiveAcc, connectedWallet);
      }
    }
  }, [hookAcc]);

  useEffect(() => {
    if (typeof library !== 'undefined' && account) {
      dispatch(
        setProvider({
          provider: library?.provider,
          address: account,
        })
      );

      handleClose();
    }
  }, [library]);

  useEffect(() => {
    if (
      addresses &&
      !userConnected &&
      walletName &&
      walletName !== SupportedWallets.walletconnect
    ) {
      getAuthToken(addresses, walletName);
    } else if (
      addresses &&
      !userConnected &&
      walletName &&
      walletName === SupportedWallets.walletconnect
    ) {
      getAuthToken(addresses, walletName);
    }
  }, [addresses, userConnected]);

  useEffect(() => {
    if (userConnected && connectedWallet) {
      setTimeout(() => {
        connectToWallet(connectedWallet);
      }, 0);
    }
  }, [userConnected, connectedWallet]);

  useEffect(() => {
    if (walletName) {
      connectToWallet(walletName);
    }
  }, [walletName]);

  const connectToWallet = async (title: any) => {
    setWalletType(title);
    let obj: any;
    if (title === SupportedWallets.ethereum) {
      obj = connectorType('inject');
    } else if (title === SupportedWallets.walletconnect) {
      obj = connectorType(SupportedWallets.walletconnect);
    }
    if (obj) {
      try {
        handleClose();
        await obj.activate();
      } catch (error) {
        if (String(error).includes('Unsupported chains')) {
          if (userConnected) {
            dispatch(setModalNetwork(true));
            dispatch(
              setChainErrorMsg('Please select the below networks to proceed')
            );
          } else {
            ToastShow('error', 'Something went wrong while connecting');
            obj?.deactivate ? obj?.deactivate() : obj.resetState();
            dispatch(
              walletCredentials({
                userConnected: false,
                connectedWallet: '',
              })
            );
            dispatch(setAddress(undefined));
            dispatch(setProvider({ provider: {}, address: '' }));
            localStorage.clear();
          }
        } else {
          obj?.deactivate ? obj?.deactivate() : obj.resetState();
          dispatch(
            walletCredentials({
              userConnected: false,
              connectedWallet: '',
            })
          );
          dispatch(setAddress(undefined));
          dispatch(setProvider({ provider: {}, address: '' }));
        }
        setModalOpen(false);
        setWalletName('');
        return false;
      }
    }
  };

  const handelModal = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleWalletName = (name: any) => {
    setWalletName('');
    setWalletName(name);
  };

  const handleErrorWhileSign = (reloadPage: boolean = false) => {
    localStorage.removeItem('walletconnect');
    dispatch(setProvider({ provider: {}, address: '' }));

    dispatch(
      walletCredentials({
        userConnected: false,
        connectedWallet: '',
      })
    );
    dispatch(setAddress(undefined));
    setWalletType();
    setWalletName('');

    if (connector) {
      connector?.deactivate ? connector?.deactivate() : connector.resetState();
    }
    if (reloadPage) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshtoken');
      const chainId: string = localStorage.getItem('chainId') as string;
      localStorage.clear();
      localStorage.setItem('chainId', chainId);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const getAuthToken = async (address: any, walletType = '') => {
    if (!account) return;
    try {
      dispatch(
        setProvider({
          provider: library.provider,
          address: address,
        })
      );
      dispatch(
        walletCredentials({
          userConnected: true,
          connectedWallet: walletType,
        })
      );
      dispatch(setAddress(address));

      if (
        walletType === SupportedWallets.walletconnect &&
        Number(localStorage.getItem('chainId')) !== chainIdHook
      ) {
        ToastShow(
          'error',
          'Make Sure the chain in wallet is same as per current chain'
        );
        handleErrorWhileSign(true);
      } else if (
        walletType === SupportedWallets.walletconnect &&
        Number(localStorage.getItem('chainId')) !== chainId &&
        chainId === chainIdHook
      ) {
        ToastShow(
          'error',
          'Make Sure the chain in wallet is same as per current chain'
        );
        handleErrorWhileSign(true);
      }
    } catch (err: any) {
      handleErrorWhileSign();
    }
  };

  const handleDisconnectModal = () => {
    setDisconnectModalOpen((prevState) => !prevState);
  };

  return (
    <div className={styles['containerCls']}>
      {userConnected ? (
        <Button
          onClick={handleDisconnectModal}
          className={cx(
            styles.user__button_address,
            { [styles.user__button_active]: userConnected },
            styles.user__button__connected         
          )}
        >
          {/* <img src={walletIcon} alt='wallet' /> */}
          <Text
            type='span'
            className={cx(styles.user__address, styles.user__connected)}
          >
            {userAddress}
          </Text>
        </Button>
      ) : (
        <div
          onClick={handelModal}
          className={cx(styles.user__button_address, styles.user__button__unconnected)}
          id={"connectWalletBtn"}
        >
          {/* <img src={walletIcon} alt='wallet' />
          <Text type='span' className={cx(styles.user__address)}>
            Connect Wallet
          </Text> */}
          <img src={connectWalletBtn} />
        </div>
      )}

      <div>
        <SwitchChainBtn />
      </div>

      <WalletModal
        modalOpen={modalOpen}
        setWalletName={handleWalletName}
        handleClose={handleClose}
      ></WalletModal>

      {disconnectModalOpen && (
        <Disconnect
          setWalletName={setWalletName}
          handleDisconnectModal={handleDisconnectModal}
        />
      )}
    </div>
  );
};
export default MultiWallet;
