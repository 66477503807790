/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';
import { ModalContext } from 'context';
import { Footer } from 'components';
import { Header } from 'containers';
import { PathName } from 'appConstants';
import styles from './styles.module.scss';

export const Layout = () => { 
  const { isModalOpen, currentModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  const isAdminPath = useMemo(() => pathname.includes(PathName.admin), [pathname]);
  const isLoginPath = useMemo(() => pathname.includes(PathName.login), [pathname]);

  return (
    <>
      {/* <div className={styles.layout__bg} /> */}
      <Header isLoginPath={isLoginPath} />
      <div className={cx(styles.layout__container)}>
        <div className={styles.bodyLayout}>
          <Outlet />
        </div>
      </div>
      <Footer
        isAdminPath={isAdminPath || isLoginPath}
        className={cx({ [styles.footer__admin]: isAdminPath || isLoginPath })}
      />
      {isModalOpen && (<>{currentModal}</>)}
    </>
  );
};
