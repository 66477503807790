import { useWeb3React } from '@web3-react/core';
import { Modal, Text, Button, ModalWrapper } from 'components';
import { useDispatch } from 'react-redux';
import { setProvider } from 'store/web3/provider';
import { walletCredentials } from 'store/web3/connectedUserInfo';
import { setAddress } from 'store/web3/walletSlice';
import styles from './disconnect.module.scss';

const DisconnectWallet = ({
  setWalletName,
  handleDisconnectModal,
}: {
  setWalletName: (val: string) => void;
  handleDisconnectModal: () => void;
}) => {
  const dispatch = useDispatch();
  const { connector } = useWeb3React();

  const disconnectWallet = () => {
    setWalletName('');

    if (connector) {
      connector?.deactivate ? connector?.deactivate() : connector.resetState();
    }

    localStorage.removeItem('walletconnect');
    dispatch(
      walletCredentials({
        userConnected: false,
        connectedWallet: '',
      })
    );
    dispatch(setAddress(undefined));
    dispatch(
      setProvider({
        provider: {},
        address: '',
      })
    );
    handleDisconnectModal();
    const chainId: string = localStorage.getItem('chainId') as string;
    localStorage.clear();
    localStorage.setItem('chainId', chainId);
  };

  return (
    <>
      <Modal isOpen={true} onClose={handleDisconnectModal}>
        <ModalWrapper>
          <section className={styles.modalDisconnectWallet}>
            <Text type='h3' className={styles.title}>
              Are you sure you want to disconnect your wallet?
            </Text>
            <Button
              theme='round'
              isFullWidth
              className={styles.cancel}
              onClick={handleDisconnectModal}
            >
              CANCEL
            </Button>
            <Button
              theme='outline'
              isFullWidth
              className={styles.confirm}
              onClick={disconnectWallet}
            >
              CONFIRM
            </Button>
          </section>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default DisconnectWallet;
