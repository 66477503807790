import React, { FC } from 'react';
import { Text } from 'components';
import { LoginForm } from 'containers';
import styles from './styles.module.scss';

export const Login: FC = () => (
  <>
    <Text
      className={styles.title}
      type="h1"
    >
      Admin Panel
    </Text> 
    <LoginForm className={styles.form} />
  </>
);
