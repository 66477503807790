import React from 'react';
import { Route, Routes as RoutesDom, Navigate } from 'react-router-dom';
import { PathName } from 'appConstants';
import { Layout } from 'containers';
import {
  StorePage, Login, Admin, 
} from 'pages';

export const Routes = () => (
  <RoutesDom>
    <Route
      path={PathName.store}
      element={<Layout />}
    >
      <Route
        index
        element={<StorePage />}
      />

      <Route
        path={PathName.login}
        element={<Login />}
      />
      <Route
        path={PathName.admin}
        element={<Admin />}
      />
      <Route
        path="*"
        element={(
          <Navigate
            to="/"
            replace
          />
        )}
      />
    </Route>
  </RoutesDom>
);
