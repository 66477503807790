export enum Nft {
  Rare = 'Rare',
  Epic = 'Epic',
  Legendary = 'Legendary',
  Mystic = 'Mystic',
}

export type NftInfo = {
  title: string,
  rune: number,
  price: string,
  priceForRune?: string,
};
