import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import { strings } from 'appConstants';
import { Button, Input, Modal } from 'components';
import { ModalContext } from 'context';
import { Nft, PartialRecord } from 'types';
import { krakenSelectors } from 'store/kraken/selectors';
import { getKrakenContractFromProvider } from 'api/krakenContract';
import { BigNumber } from 'bignumber.js';
import { toast } from 'react-toastify';
import { PackNftPoint } from './PackNftPoint';
import styles from './styles.module.scss';

type ModalPriceConfigurationProps = {
  className?: string;
  getPrices?: () => void;
};

export const ModalPriceConfiguration: FC<ModalPriceConfigurationProps> = ({
  className = '',
  getPrices,
}) => {
  const prices = useSelector(krakenSelectors.getProp('prices'), shallowEqual);
  // const statusChangePrice = useSelector(krakenSelectors.getStatus(
  //   KrakenActionTypes.ChangePrice,
  // ));
  const { address } = useSelector((state: any) => state.wallet);
  const { provider } = useSelector((state: any) => state.providerReducer);
  const { currentNetwork } = useSelector((state: any) => state.network);

  const [priceLoader, setPriceLoader] = useState<PartialRecord<Nft, boolean>>({
    Rare: false,
    Mystic: false,
    Legendary: false,
    Epic: false,
  });

  const { closeModal } = useContext(ModalContext);

  const nftPricesPack = useMemo(
    () => [
      {
        name: Nft.Rare,
        price: prices.Rare.price,
      },
      {
        name: Nft.Epic,
        price: prices.Epic.price,
      },
      {
        name: Nft.Legendary,
        price: prices.Legendary.price,
      },
      {
        name: Nft.Mystic,
        price: prices.Mystic.price,
      },
    ],
    [prices]
  );

  const [rarePrice, setRarePrice] = useState('');
  const [epicPrice, setEpicPrice] = useState('');
  const [legendaryPrice, setLegendaryPrice] = useState('');
  const [mysticPrice, setMysticPrice] = useState('');

  const inputsPrices = useMemo(
    () => [
      {
        label: Nft.Rare,
        value: rarePrice,
        setValue: setRarePrice,
      },
      {
        label: Nft.Epic,
        value: epicPrice,
        setValue: setEpicPrice,
      },
      {
        label: Nft.Legendary,
        value: legendaryPrice,
        setValue: setLegendaryPrice,
      },
      {
        label: Nft.Mystic,
        value: mysticPrice,
        setValue: setMysticPrice,
      },
    ],
    [epicPrice, legendaryPrice, mysticPrice, rarePrice]
  );

  // const onClickConfirm = useCallback(() => {
  //   const res: PartialRecord<Nft, string> = {};
  //   if (rarePrice) res[Nft.Rare] = rarePrice;
  //   if (epicPrice) res[Nft.Epic] = epicPrice;
  //   if (legendaryPrice) res[Nft.Legendary] = legendaryPrice;
  //   if (mysticPrice) res[Nft.Mystic] = mysticPrice;

  // dispatch(krakenChangePrice(res));
  //   console.log(res);
  // }, [
  //   dispatch,
  //   epicPrice,
  //   legendaryPrice,
  //   mysticPrice,
  //   rarePrice,
  // ]);

  const apiGetNftTiesKraken = async (nftType: Nft): Promise<string> => {
    const contract = getKrakenContractFromProvider(
      process.env.REACT_APP_POLYGON_RPC_URL
    );
    if (nftType === Nft.Epic) {
      return contract.methods.EPIC().call();
    }
    if (nftType === Nft.Legendary) {
      return contract.methods.LEGENDARY().call();
    }
    if (nftType === Nft.Mystic) {
      return contract.methods.MYSTIC().call();
    }
    return contract.methods.RARE().call();
  };

  const setNewPrice = async (packType: Nft) => {
    if (currentNetwork.title !== 'Polygon') {
      return toast.info('Please switch your chain to Polygon');
    }
    const res: PartialRecord<Nft, string> = {};
    if (rarePrice) res[Nft.Rare] = rarePrice;
    if (epicPrice) res[Nft.Epic] = epicPrice;
    if (legendaryPrice) res[Nft.Legendary] = legendaryPrice;
    if (mysticPrice) res[Nft.Mystic] = mysticPrice;

    const packPrice: string | undefined = res[packType];

    if (!packPrice) return toast.info('Please enter the price.');

    const numberPattern = /^[0-9]+(?:\.[0-9]+)?$/;
    if (
      !numberPattern.test(packPrice) ||
      (packPrice && Number(packPrice) <= 0)
    ) {
      return toast.info('Value is not valid');
    }

    try {
      setPriceLoader((prevState) => ({ ...prevState, [packType]: true }));
      const tier = await apiGetNftTiesKraken(packType);
      console.log(packType, tier);
      const contract = getKrakenContractFromProvider(provider);
      const price = new BigNumber(packPrice)
        .multipliedBy(new BigNumber(10).pow(6))
        .toString();

      const callRes = await contract.methods
        .changeMintPrice(tier, price)
        .send({ from: address });
      if (callRes) {
        toast.success('Price has been set.');
        closeModal();
        if (getPrices) getPrices();
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong!');
    } finally {
      setPriceLoader((prevState) => ({ ...prevState, [packType]: false }));
    }
  };

  return (
    <Modal isOpen onClose={closeModal}>
      <section
        className={cx(styles.modal_price_configuration__container, className)}
      >
        <h2 className={styles.modal_price__title}>
          {strings.priceConfiguration}
        </h2>
        <article className={styles.modal_price__table}>
          <div className={styles.modal_price__current_prices}>
            <h3 className={styles.modal_price__table_title_current}>
              {strings.currentPrice}
            </h3>
            {nftPricesPack.map((pricePack) => (
              <PackNftPoint
                key={pricePack.name}
                name={pricePack.name}
                classNameContainer={styles.modal_price__table_row}
                price={+pricePack.price}
              />
            ))}
          </div>
          <div className={styles.modal_price__input_prices}>
            <h3 className={styles.modal_price__table_title_price}>
              {strings.newPrice}
            </h3>
            {inputsPrices.map((inputPrice, index) => (
              <div style={{ display: 'flex' }} key={index}>
                <Input
                  key={inputPrice.label}
                  type="number"
                  value={inputPrice.value}
                  placeholder=""
                  isNumberOnly
                  suffix={strings.usdt}
                  className={styles.modal_price__input_field}
                  onChange={inputPrice.setValue}
                />
                <Button
                  className={styles.modal_price__confirm_button}
                  onClick={() => setNewPrice(inputPrice.label)}
                  theme="admin"
                  isLoading={priceLoader[inputPrice.label]}
                  disabled={priceLoader[inputPrice.label]}
                >
                  {strings.confirm}
                </Button>
              </div>
            ))}
          </div>
        </article>
        <div className={styles.modal_price__button_container}>
          <Button
            className={styles.modal_price__cancel_button}
            onClick={closeModal}
            theme="admin"
          >
            {strings.cancel}
          </Button>
          {/* <Button
            className={styles.modal_price__confirm_button}
            onClick={setNewPrice}
            theme='admin'
            isLoading={priceLoader}
            disabled={priceLoader}
          >
            {strings.confirm}
          </Button> */}
        </div>
      </section>
    </Modal>
  );
};
