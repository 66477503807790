// Action types
const SET_CHAIN_ERROR_MSG = 'network/setChainErrorMsg';
const SET_CURRENT_NETWORK = 'network/setCurrentNetwork';
const SET_NETWORK_LIST = 'network/setNetworkList';

// Action creators
export const setChainErrorMsg = (errorMessage: string | null) => ({
  type: SET_CHAIN_ERROR_MSG,
  payload: errorMessage,
});

export const setCurrentNetwork = (currentNetwork: any) => ({
  type: SET_CURRENT_NETWORK,
  payload: currentNetwork,
});

export const setNetworkList = (currentNetwork: any) => ({
  type: SET_NETWORK_LIST,
  payload: currentNetwork,
});

// Reducer
interface NetworkState {
  errorMessage: string | null;
  isLoading: boolean;
  networksList: any[]; // Update the type to match your data structure
  currentNetwork: any; // Update the type to match your data structure
}

const initialState: NetworkState = {
  errorMessage: null,
  isLoading: false,
  networksList: [],
  currentNetwork: null,
};

const networkReducer = (state: NetworkState = initialState, action: any) => {
  switch (action.type) {
    case SET_CHAIN_ERROR_MSG:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SET_CURRENT_NETWORK:
      return {
        ...state,
        currentNetwork: action.payload,
      };
    case SET_NETWORK_LIST:
      return {
        ...state,
        networksList: action.payload,
      };
    default:
      return state;
  }
};

export default networkReducer;
