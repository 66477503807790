import { fork } from 'redux-saga/effects';
// sagas
import AuthSaga from './auth/sagas';
import krakenSaga from './kraken/sagas';
import usdtSaga from './usdt/sagas';
import metamaskSaga from './metamask/sagas';

export default function* rootSaga() {
  yield fork(AuthSaga);
  yield fork(krakenSaga);
  yield fork(usdtSaga);
  yield fork(metamaskSaga);
  //
}
