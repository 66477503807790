import { decimalPlaces, chains, Network } from 'appConstants';
import { BigNumber } from 'bignumber.js';
import Web3 from 'web3';

export const getWeb3 = () => {
  const web3 = new Web3(Web3.givenProvider || chains[Network.PolygonMain].rpcUrls[0]);
  return web3;
};

export const getAddress = async (): Promise<string> => {
  const web3 = getWeb3();
  const accounts = await web3.eth.getAccounts();
  return accounts[0];
};

export const getChainId = async (): Promise<number> => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  return chainId;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContract = (address: string, abi: any) => {
  const web3 = getWeb3();
  const abiFromJson = JSON.parse(JSON.stringify(abi));
  const contract = new web3.eth.Contract(abiFromJson, address);
  return contract;
};

export const getBalanceMatic = async (address: string): Promise<string> => {
  const web3 = getWeb3();
  const result = await web3.eth.getBalance(address);
  return new BigNumber(web3.utils.fromWei(result)).decimalPlaces(decimalPlaces).toString();
};

export * from './kraken';
export * from './usdt';
