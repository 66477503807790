import { shallowEqual, useSelector } from 'react-redux';
import { metamaskSelectors } from 'store/metamask/selectors';
import { krakenSelectors } from 'store/kraken/selectors';
import { MetamaskStatus } from 'types';

export const useWallet = () => {
  const {
    status,
    address,
  } = useSelector(metamaskSelectors.getState, shallowEqual);

  const isAdmin = useSelector(krakenSelectors.getProp('isAdmin'));

  const isWalletConnected = status === MetamaskStatus.CONNECTED && address !== '';

  return {
    isWalletConnected,
    status,
    address,
    isAdmin,
  };
};
