import { Nft, Price } from 'types';

export const runes = {
  [Nft.Rare]: 1,
  [Nft.Epic]: 2,
  [Nft.Legendary]: 3,
  [Nft.Mystic]: 5,
};

export const getInfoNft = (typeNft: Nft, prices: Record<Nft, Price>): {
  title: string,
  rune: number,
  price: string,
  priceForRune?: string,
} => {
  if (typeNft === Nft.Rare) {
    return {
      title: 'RARE PACK',
      rune: runes.Rare,
      price: prices.Rare.price,
    };
  } 
  
  if (typeNft === Nft.Epic) {
    return {
      title: 'EPIC PACK',
      rune: runes.Epic,
      price: prices.Epic.price,
      priceForRune: '999',
    };
  } 
  
  if (typeNft === Nft.Legendary) {
    return {
      title: 'LEGENDARY PACK',
      rune: runes.Legendary,
      price: prices.Legendary.price,
      priceForRune: '899',
    };
  } 
  
  return {
    title: 'MYSTIC PACK',
    rune: runes.Mystic,
    price: prices.Mystic.price,
    priceForRune: '779',
  };
};
