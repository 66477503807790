import React, { FC, useCallback, useState } from 'react';
import cx from 'classnames';
import {
  Button, Input, Text,
} from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { RequestStatus } from 'types';
import { authSelectors } from 'store/auth/selectors';
import { authLogin } from 'store/auth/actionCreators';
import { AuthActionTypes } from 'store/auth/actionsTypes';
import styles from './styles.module.scss';

type LoginFormProps = {
  className?: string,
};

export const LoginForm: FC<LoginFormProps> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const statusLogin = useSelector(authSelectors.getStatus(AuthActionTypes.Login));

  const handleLogin = useCallback(() => {
    dispatch(authLogin({ login, password }));
  }, [dispatch, login, password]);

  return (
    <section className={cx(styles.loginForm, className)}>
      <Text
        className={styles.titleLogin}
        type="h2"
      >
        Log In
      </Text>
      <Text 
        className={styles.descLogin}
      >
        Please input your credentials to get access to the Admin Panel
      </Text>
      <Input
        placeholder="Login"
        className={styles.login}
        value={login}
        onChange={(value) => setLogin(value)}
      />
      <Input
        type="password"
        placeholder="Password"
        className={styles.password}
        value={password}
        onChange={(value) => setPassword(value)}
      />
      <Button
        className={styles.buttonEnter}
        onClick={handleLogin}
        isLoading={statusLogin === RequestStatus.REQUEST}
        disabled={statusLogin === RequestStatus.REQUEST}
      >
        Enter
      </Button>
    </section>
  );
};
