import { contracts } from 'appConstants';
import { UsdtAbi } from 'assets/abi';
import { ethers } from 'ethers';

export const getUSDTContract = () => {
  if (window.ethereum === undefined) return false;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const CONTRACT = new ethers.Contract(contracts.Usdt, UsdtAbi, provider.getSigner());
  if (!CONTRACT) {
    throw new Error('undefined USDTContract');
  }
  return CONTRACT;
};
