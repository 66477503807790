import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setChainID as RDSetChainID,
  setModalNetwork,
} from '../../../store/web3/walletSlice';
import { getChainId, setChainId } from '../wallet';
import SwitchChainModal from './SwitchChainModal';
import {
  dataSwitchChainTestnet,
  dataSwitchChainMainnet,
} from '../constants/chain';
import styles from './switchChain.module.scss';

const MultiWallet = () => {
  const dispatch = useDispatch();
  const dataSwitchChain =
    process.env.REACT_APP_NETWORK_TYPE === 'testnet'
      ? dataSwitchChainTestnet
      : dataSwitchChainMainnet;

  const { chainId } = useSelector((state: any) => state.wallet);

  const onClickButton = () => {
    dispatch(setModalNetwork(true));
  };

  const getLogoChain = (chain: any) => {
    let result;
    if (chain) {
      for (let i = 0; i < dataSwitchChain.length; i =+1) {
        if (dataSwitchChain[i].key === Number(chain)) {
          result = dataSwitchChain[i].logo;
          break;
        }
      }
    }
    return result;
  };

  useEffect(() => {
    if (chainId !== undefined) {
      setChainId(chainId);
    } else {
      let chain = getChainId();
      chain = chain
        ? Number(chain)
        : Number(
            process.env.REACT_APP_NETWORK_TYPE === 'testnet'
            ? dataSwitchChainTestnet[0].chainID
            : dataSwitchChainMainnet[0].chainID,
          );
      dispatch(RDSetChainID(chain));
    }
  }, [chainId, dispatch]);

  return (
    <>
      <img
        onClick={() => onClickButton()}
        src={getLogoChain(chainId) || dataSwitchChain[0].logo}
        alt='Eth icon'
        className={styles['chainImg']}
      />
      <SwitchChainModal />
    </>
  );
};
export default MultiWallet;
