import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import {
  RequestStatus,
} from 'types';
import { apiApproveUsdtToKrakenMax } from 'api';
import { 
  usdtSetStatus, usdtApprove, usdtGetData,
} from 'store/usdt/actionCreators';

export function* usdtApproveSaga({ type }: ReturnType<typeof usdtApprove>) {
  try{
    yield put(usdtSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(apiApproveUsdtToKrakenMax);

    yield put(usdtGetData());

    yield put(usdtSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(usdtSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
