import React, { 
  FC, useState, ChangeEvent, useCallback, useEffect,
} from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

interface InputWithButtonProps {
  className?: string,
  valueDefault?: number, 
  maxValue: number, 
  onChange?: (val: number) => void,
}

export const InputWithButton: FC<InputWithButtonProps> = ({ 
  className = '', onChange, valueDefault, maxValue,
}) => {
  const [value, setValue] = useState<number>(1);

  const handleMinus = useCallback(() => {
    const newValue = value > 1 ? value - 1 : 1;
    setValue(newValue);
    if(onChange) onChange(newValue);
  }, [onChange, value]);

  const handlePlus = useCallback(() => {
    if (maxValue >= value + 1) {
      setValue(value + 1);
      if(onChange) onChange(value + 1);
    }
  }, [maxValue, onChange, value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = +e.target.value;
    if(newValue > 0 && maxValue >= newValue) {
      setValue(newValue);
      if(onChange) onChange(newValue);
    } else if (maxValue < newValue) {
      setValue(maxValue);
      if(onChange) onChange(maxValue); 
    } else {
      setValue(1);
      if(onChange) onChange(1);  
    }
  }, [maxValue, onChange]);

  useEffect(() => {
    if(valueDefault) {
      setValue(valueDefault);
    }
  }, [valueDefault]);

  return (
    <div className={cx(styles.inputWithButton, className)}>
      <button
        className={styles.buttonMinus}
        onClick={handleMinus}
      >
        -
      </button>
      <input
        className={styles.input}
        value={value}
        onChange={handleChange}
      />
      <button
        className={styles.buttonPlus}
        onClick={handlePlus}
      >
        +
      </button>
    </div>
  );
};
