import React from "react";
import Countdown from "react-countdown";

const Completionist = ({ completeMsg }: { completeMsg?: string }) => (
  <span>{completeMsg}</span>
);

const Counter = ({
  time,
  completeMsg,
  onComplete,
}: {
  time: number;
  onComplete?: () => void;
  completeMsg?: string;
}) => {
  return (
    <Countdown
      date={time}
      onComplete={() => {
        onComplete && onComplete();
      }}
      renderer={({
        days,
        hours,
        minutes,
        seconds,
        completed,
      }: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        completed: boolean;
      }) => {
        if (completed) {
          return <Completionist completeMsg={completeMsg} />;
        } else {
          return (
            <span>
              {days} d | {hours} h | {minutes} m | {seconds} s
            </span>
          );
        }
      }}
    ></Countdown>
  );
};

export const CountDown = React.memo(Counter);
