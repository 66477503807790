import React, {
  FC, useCallback,
} from 'react';
import cx from 'classnames';
import { Checkbox, Text } from 'components';
import { IUserType } from '../constants';
import styles from './styles.module.scss';

interface WhitelistAddressesProps {
  classNameContainer?: string,
  addressesList: IUserType[],
  selectedAddresses: boolean[],
  handleSelectedAddresses: (val: number) => void,
}
const WhitelistAddresses: FC<WhitelistAddressesProps> = ({
  classNameContainer, addressesList,
  selectedAddresses, handleSelectedAddresses, 
}) => {
  const onSelectPeriod = useCallback((index: number) => {
    if (selectedAddresses?.length > 0) {
      handleSelectedAddresses(index);
    }
  }, [handleSelectedAddresses, selectedAddresses]);

  return (
    <article className={cx(styles.w_list__container, classNameContainer)}>
      {addressesList.map((addressValue, idx) => (
        <div key={idx} style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        <Checkbox
          isChecked={selectedAddresses[idx]}
          onSelect={onSelectPeriod}
          index={idx}
          value={addressValue.address}
          classNameElementBox={cx(styles.point__container, styles.ma_radio__elem_box)}
          classNameElement={styles.ma_radio__element}
          classNameElementSelected={styles.ma_radio__elem_selected}
          key={idx}
        />
        <div style={{marginLeft:10}}>
          <Text>{addressValue.whiteListType}</Text>
        </div>
        </div>
      ))}
    </article>
  );
};

export { WhitelistAddresses };
