import React, { FC } from "react";
import {
  TelegramImg,
  DiscordImg,
  XcomImg,
  CharacterImg,
  BallImg,
  TreasureBig,
  TreasureSmall,
} from "assets/images/index";
import cx from "classnames";
import styles from "./styles.module.scss";

const TraitRewards: FC = () => {
  return (
    <div>
      <h1 className={styles.store__title}>Trait Rewards</h1>
      <div className={styles.rewardsContainer}>
        <div className={styles.div0}>
          <iframe
            src="https://www.youtube.com/embed/UGg3E255WkY?autoplay=1"
          />
        </div>
        <div className={styles.div1}>
          Special Traits can also be passed down to new generations of BTMs via
          the crafting system. When this happens, the owner of the K-Rune will
          earn a 1% Trait Reward from each BTM that inherits their Special
          Trait. This effect can cascade to new generations infinitely.
        </div>

        <div className={styles.div2}>
          <p className={styles.text1}>Trait Rewards Calculation:</p>
          <span>1%</span>&nbsp;
          <span className={styles.text2}>x average</span>&nbsp;
          <span className={styles.text2}>BTM selling price</span>&nbsp;
          <span>X The number of</span>&nbsp;
          <span className={styles.text2}>related-DNA BTMs sold</span>
          <p className={styles.text3}>=Total Earnings</p>
        </div>

        <div className={styles.div3}>
          <p>BTM Minimum Selling Price: $500</p>
        </div>

        <div className={styles.div4}>
          <p>Perpetual Earning Potential</p>
        </div>

        <div className={styles.div5}>
          <div className={styles.div51}>
            <img src={TreasureBig} alt="TreasureBig" />
            <div>BTM with your K-Rune DNA</div>
          </div>
          <div className={styles.div52}>
            <div>Traits Rewards to K-Rune Owner</div>
            <div className={styles.divArrow}>
              <i className={cx(styles["arrowHead"], styles["left"])}></i>
              <div className={styles.arrowLine}></div>
            </div>
          </div>
          <div className={styles.div53}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className={styles.divImg}>
                <img src={TreasureSmall} alt="TreasureBig" />
                <img src={TreasureSmall} alt="TreasureBig" />
              </div>
              <div className={styles.divImg}>
                <img src={TreasureSmall} alt="TreasureBig" />
                <img src={TreasureSmall} alt="TreasureBig" />
              </div>
              <div className={styles.divImg}>
                <img src={TreasureSmall} alt="TreasureBig" />
                <img src={TreasureSmall} alt="TreasureBig" />
              </div>
              <div className={styles.divImg}>
                <img src={TreasureSmall} alt="TreasureBig" />
              </div>
            </div>
            <div>
              Newly Crafted Playable BTMs with your K-Rune DNA sold on the
              market.
            </div>
          </div>
        </div>

        <div className={styles.div6}>
          Bounty Temple Monuments (BTMs) that are newly crafted are sold at
          a&nbsp;
          <span className={styles.text2}>minimum floor price of $500.</span>
          This is a fixed price for all newly crafted BTMs in order to protect
          secondary market value. flood
        </div>

        <div className={styles.div7}>
          Picture&nbsp;
          <span className={styles.text2}>
            100,000 units of playable NFTs (BTM)
          </span>
          &nbsp; associated with&nbsp;
          <span className={styles.text2}>your DNA,</span>&nbsp; each traded just
          once. With a&nbsp;
          <span className={styles.text2}>
            1% reward of a minimum $5 per NFT,
          </span>
          &nbsp;the cumulative Trait Rewards soar to&nbsp;
          <span className={styles.text2}>$500,000.</span>
          The beauty of this system lies in its limitless potential, driven by
          the collaborative efforts of the community.
        </div>

        <div className={styles.div8}>
          The more BTMs the community crafts and trades on the marketplace, the
          more K-Rune owners earn!
        </div>

        <div className={cx(styles.div4, styles.marginCls)}>
          <p>Stable Secondary Market</p>
        </div>

        <div className={styles.div9}>
          <div className={cx(styles.div91)}>
            <img src={BallImg} />
          </div>
          <div className={cx(styles.div92)}>
            <div className={cx(styles.div921)}>
              Newly crafted BTMs can ONLY be sold at a minimum of $500. This is
              a cap that is set to protect the value of these playable NFTs.
            </div>

            <div className={styles.div922}>
              The reason is simple: newly crafted BTMs a&nbsp;
              <span className={styles.text2}>ensure you ROI</span>
              &nbsp;at the start!
            </div>

            <div className={styles.div923}>
              These BTMs have a&nbsp;
              <span className={styles.text2}>75 day protection&nbsp;</span>
              called the&nbsp;
              <span className={styles.text1}>“Barrier Effect”</span>, where
              players won’t be affected by the Weather Effect - an AI algorithm
              made to control the minting rate o $GIA. Ensuring that our utility
              token is stable.
            </div>
          </div>
        </div>

        <div className={styles.div10}>
          <div className={cx(styles.div101)}>
            <img src={CharacterImg} />
          </div>
          <div className={cx(styles.div102)}>
            <div className={cx(styles.div1021)}>
              Ready to join the P2EE Revolution?
            </div>

            <div className={styles.div1022}>
              Join the Bounty Temple ecosystem and discover boundless earnings.
              <br />
              Want to get a K-Rune? Join our community channels and follow our
              socials to get a Whitelist!
            </div>

            <div className={styles.div1023}>
              <a href="https://discord.com/invite/bountytemple" target="_blank">
                <img src={DiscordImg} />
              </a>
              <a href="https://x.com/BountyTemple" target="_blank">
                <img src={XcomImg} />
              </a>
              <a href="https://t.me/bountytemple" target="_blank">
                <img src={TelegramImg} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TraitRewards);
