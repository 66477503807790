import { ActionFn } from 'types/redux';
import { UsdtState } from 'types/store/UsdtState';
import { usdtSetState, usdtSetStatus } from './actionCreators';
import { UsdtActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UsdtHandlerFn<F extends (...args : any) => any> = ActionFn<UsdtState, ReturnType<F>>;

const setState: UsdtHandlerFn<typeof usdtSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: UsdtHandlerFn<typeof usdtSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

export const usdtHandlers = {
  [UsdtActionTypes.SetState]: setState,
  [UsdtActionTypes.SetStatus]: setStatus,
};
