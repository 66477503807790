import React, { FC } from 'react';
import { strings } from 'appConstants';
import cx from 'classnames';
import styles from './styles.module.scss';

interface PackNftPointProps {
  classNameContainer?: string,
  name?: string,
  price?: number,
}
const PackNftPoint:FC<PackNftPointProps> = ({
  classNameContainer, name, price,
}) => (
  <section className={cx(styles.pack_nft__container, classNameContainer)}>
    <span className={styles.pack_nft__name}>
      {`${name}:`}
    </span>
    <span className={styles.pack_nft__price_value}>
      {`${price} ${strings.usdt}`}
    </span>
  </section>
);

export { PackNftPoint };
