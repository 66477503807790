/* eslint-disable no-unused-vars */

import { metamaskIcon } from 'assets/images';
import { walletConnectIcon } from 'assets/images';

export enum SupportedWallets {
  ethereum = 'ethereum',
  walletconnect = 'walletconnect',
}

export const MultiWalletData = [
  {
    icon: metamaskIcon,
    title: 'MetaMask',
    label: 'Connect to your MetaMask Wallet',
    key: SupportedWallets.ethereum,
  },
  {
    icon: walletConnectIcon,
    title: 'WalletConnect',
    label: 'Connect your favorite wallets',
    key: SupportedWallets.walletconnect,
  },
];
