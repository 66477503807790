import { put, call, select } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { 
  apiBalanceKraken, apiGetIsAdminKraken, getAddress,
} from 'api';
import {
  RequestStatus, Unwrap,
} from 'types';
import { 
  krakenSetStatus, krakenGetData, krakenSetState,
} from 'store/kraken/actionCreators';
import { krakenSelectors } from 'store/kraken/selectors';

export function* krakenGetDataSaga({ type }: ReturnType<typeof krakenGetData>) {
  try{
    yield put(krakenSetStatus({ type, status: RequestStatus.REQUEST }));

    const isAdmin: Unwrap<typeof apiGetIsAdminKraken> = yield call(apiGetIsAdminKraken);
    
    const balance: Unwrap<typeof apiBalanceKraken> = yield call(apiBalanceKraken);

    const address: Unwrap<typeof getAddress> = yield call(getAddress);
    
    const whitelist: string[] = yield select(krakenSelectors.getProp('whitelist'));

    const isAddressInWhitelist = whitelist.includes(address.toLocaleLowerCase());

    yield put(krakenSetState({ 
      balance, isAdmin, isAddressInWhitelist,
    }));
    
    yield put(krakenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(krakenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
