import { ActionFn } from 'types/redux';
import { KrakenState } from 'types/store/KrakenState';
import { krakenSetState, krakenSetStatus, krakenSetStatusMint } from './actionCreators';
import { KrakenActionTypes } from './actionsTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KrakenHandlerFn<F extends (...args : any) => any> = ActionFn<KrakenState, ReturnType<F>>;

const setState: KrakenHandlerFn<typeof krakenSetState> = (
  state,
  { payload },
) => ({
  ...state,
  ...payload,
});

const setStatus: KrakenHandlerFn<typeof krakenSetStatus> = (
  state,
  { payload },
) => ({
  ...state,
  ui: {
    ...state.ui,
    [payload.type]: payload.status,
  },
});

const setStatusMint: KrakenHandlerFn<typeof krakenSetStatusMint> = (
  state,
  { payload },
) => ({
  ...state,
  mintStatus: {
    ...state.mintStatus,
    [payload.nftType]: payload.status,
  },
});

export const krakenHandlers = {
  [KrakenActionTypes.SetState]: setState,
  [KrakenActionTypes.SetStatus]: setStatus,
  [KrakenActionTypes.SetStatusMint]: setStatusMint,
};
