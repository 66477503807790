import React, { FC } from 'react';
import cx from 'classnames';
import { strings } from 'appConstants';
import { Button, Input } from 'components';
import styles from './styles.module.scss';

interface AdminInputWithButtonProps {
  onCreateClick?: () => void;
  onChangeValue?: (val: string) => void;
  classNameContainer: string;
  value: string;
  buttonIsLoading?: boolean;
  buttonIsDisabled?: boolean;
}

const AdminInputWithButton: FC<AdminInputWithButtonProps> = ({
  onCreateClick, onChangeValue, classNameContainer, value, buttonIsLoading = false, 
  buttonIsDisabled = false,
}) => (
  <section className={cx(styles.input__container, classNameContainer)}>
    <Input
      placeholder='0x00000000000000000000000000'
      onChange={onChangeValue}
      value={value}
      className={styles.input__field}
    />
    <Button
      theme='admin'
      isFullWidth
      onClick={onCreateClick}
      className={styles.input__button}
      isLoading={buttonIsLoading}
      disabled={buttonIsDisabled}
    >
      {strings.add}
    </Button>
  </section>
);

export { AdminInputWithButton };
