import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { strings } from 'appConstants';
import { Button, Checkbox } from 'components';
import { krakenSelectors } from 'store/kraken/selectors';
import { WhitelistAddresses } from './WhitelistAddresses';
import { AdminInputWithButton } from './AdminInputWithButton';
import axios from 'axios';
import cx from 'classnames';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { messageToSign, IUserType } from './constants';
import styles from './styles.module.scss';

const whiteListTypes = [
  {
    key: 'Normal',
    value: 'Normal',
  },
  {
    key: 'OG',
    value: 'OG',
  },
];

const Whitelisted: FC = () => {
  const [addresses, setAddresses] = useState<IUserType[]>([]);
  const [addressesMasked, setAddressesMasked] = useState('');
  const [selectedAddresses, setSelectedAddresses] = useState<boolean[]>([]);

  const [statusAddWhiteList, setStatusAddWhiteList] = useState(false);
  const [statusRemoveWhiteList, setStatusRemoveWhiteList] = useState(false);

  const { provider } = useSelector((state: any) => state.providerReducer);
  const isAdmin = useSelector(krakenSelectors.getProp('isAdmin'));

  const [whiteListType, setWhiteListType] = useState(whiteListTypes[0].key);

  useEffect(() => {
    const addressesIsChecked = Array.from(
      { length: addresses.length },
      (el) => !!el
    );
    setSelectedAddresses(addressesIsChecked);
  }, [addresses]);

  useEffect(() => {
    if (isAdmin) getWhiteListData();
  }, [isAdmin]);

  const getWhiteListData = async () => {
    try {
      const res = (
        await axios.get(
          process.env.REACT_APP_API_URL + '/contract/getWhiteListUsers'
        )
      ).data;
      if (res && res.data) {
        const whitelistedAddresses: IUserType[] = res.data
          .reverse()
          .map((obj: { address: string; whiteListType: string }) => ({
            address: obj.address.toLocaleLowerCase(),
            whiteListType: obj.whiteListType,
          }));
        setAddresses(whitelistedAddresses);
      }
    } catch (err) {
      toast.error('Something went wrong while fetching whitelist user data');
    }
  };

  const addressesSelectedCount = useMemo(
    () => selectedAddresses.filter((selectEl) => !!selectEl).length,
    [selectedAddresses]
  );
  const isDisableRemove = useMemo(
    () => addressesSelectedCount === 0,
    [addressesSelectedCount]
  );

  const onChangeValue = useCallback((value: string) => {
    if (value) {
      setAddressesMasked(value);
    } else {
      setAddressesMasked('');
    }
  }, []);

  const generateSignRequest = async () => {
    const ethProvider = new ethers.providers.Web3Provider(provider);
    const signer = ethProvider.getSigner();
    const signature = await signer.signMessage(messageToSign);
    return signature;
  };

  const onCreateClick = async () => {
   
    const web3 = new Web3(provider);
    const addressList = addressesMasked.split(",")
    console.log(addressList);
    const entries = [];
    for(let i = 0; i< addressList.length; i++){
      if(!web3.utils.isAddress(addressList[i].trim())) return toast.error(`Addrerss at position ${i+1} is not valid`);
      entries.push({address: addressList[i].trim().toLowerCase(), whiteListType});
    }
    console.log(entries);
    try {
      setStatusAddWhiteList(true);
      const signature = await generateSignRequest();
      const tokenRes = (
        await axios.post(
          process.env.REACT_APP_API_URL + '/contract/addWhiteListUsers',
          {
            entries,
            signature,
            message: messageToSign,
          }
        )
      ).data;
      // console.log(tokenRes);
      if (tokenRes?.statusCode === 200 && tokenRes?.message) {
        toast.success('Address has been whitelisted');
        getWhiteListData();
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    } finally {
      setStatusAddWhiteList(false);
    }
  };

  const handleSelectedAddresses = useCallback(
    (index: number) => {
      setSelectedAddresses((prev) =>
        prev.map((el, idx) => (index === idx ? !el : el))
      );
    },
    [setSelectedAddresses]
  );

  const onRemoveClick = async () => {
    const removeAddresses = addresses.filter(
      (_, index) => selectedAddresses[index]
    );
    try {
      setStatusRemoveWhiteList(true);
      const signature = await generateSignRequest();
      const tokenRes = (
        await axios.post(
          process.env.REACT_APP_API_URL + '/contract/removeWhiteListUsers',
          {
            entries: [...removeAddresses],
            signature,
            message: messageToSign,
          }
        )
      ).data;
      // console.log(tokenRes);
      if (tokenRes?.statusCode === 200 && tokenRes?.message) {
        toast.success('Removed successfully');
        setSelectedAddresses([]);
        getWhiteListData();
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    } finally {
      setStatusRemoveWhiteList(false);
    }
  };

  const exportCsv = () => {
    const titleKeys = Object.keys(addresses[0])

    const refinedData = []
    refinedData.push(titleKeys)
    
    addresses.forEach(item => {
      refinedData.push(Object.values(item))  
    })
    
    let csvContent = ''
    
    refinedData.forEach(row => {
      csvContent += row.join(',') + '\n'
    })

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
    const objUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', objUrl)
    link.setAttribute('download', 'WhiteListed.csv')
    link.textContent = 'Click to Download'
    document.body.append(link);
    link.click();
     // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(objUrl);
}

  return (
    <section className={styles.whitelisted__container}>
      <div style={{
        display: "flex",
        justifyContent: "space-between"
    }}>
      <h2 className={styles.whitelisted__title}>
        Whitelisted addresses
      </h2>
      <Button
        theme='admin'
        onClick={exportCsv}
        className={styles.exportcsv__btn}
      >
        Export CSV
      </Button>
      </div>
      <div className={styles.whitelistTypeContainer}>
        {whiteListTypes.map((type, idx) => {
          return (
            <div key={idx} className={styles.checkboxContainer}>
              <Checkbox
                index={idx}
                isChecked={type.key === whiteListType}
                onSelect={(e) => setWhiteListType(whiteListTypes[e].key)}
                value={type.value}
                classNameElementBox={cx(
                  styles.point__container,
                  styles.ma_radio__elem_box
                )}
                classNameElement={styles.ma_radio__element}
                classNameElementSelected={styles.ma_radio__elem_selected}
                key={type.key}
              />
            </div>
          );
        })}
      </div>
      <AdminInputWithButton
        value={addressesMasked}
        onChangeValue={onChangeValue}
        onCreateClick={onCreateClick}
        classNameContainer={styles.whitelisted__input_button}
        buttonIsLoading={statusAddWhiteList}
        buttonIsDisabled={addressesMasked.length === 0}
      />

      <p className={styles.whitelisted__description}>{strings.whitelistDesc}</p>

      <WhitelistAddresses
        addressesList={addresses}
        selectedAddresses={selectedAddresses}
        handleSelectedAddresses={handleSelectedAddresses}
        classNameContainer={styles.whitelisted__list}
      />

      <Button
        theme='admin'
        onClick={onRemoveClick}
        disabled={isDisableRemove}
        className={styles.whitelisted__btn_remove}
        isLoading={statusRemoveWhiteList}
      >
        {`${strings.remove} ${addressesSelectedCount}`}
      </Button>
    </section>
  );
};

export { Whitelisted };
