export enum Network {
  PolygonMain = 'PolygonMain',
  // PolygonTest = 'PolygonTest',
}

export const networkChains: Record<Network, string> = {
  [Network.PolygonMain]: '0x89',
  // [Network.PolygonTest]: '0x13881',
};

type Chain = {
  chainId: string,
  chainName: string,
  nativeCurrency: {
    name: string,
    symbol: string,
    decimals: number,
  },
  rpcUrls: string[],
  blockExplorerUrls: string[],
};

type Chains = Pick<Record<Network, Chain>,
Network.PolygonMain>;

export const chains: Chains = {
  // [Network.PolygonTest]: {
  //   chainId: networkChains.PolygonTest,
  //   chainName: 'Matic Mumbai',
  //   nativeCurrency:
  //     {
  //       name: 'MATIC',
  //       symbol: 'MATIC',
  //       decimals: 18,
  //     },
  //   rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
  //   blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  // },
  [Network.PolygonMain]: {
    chainId: networkChains.PolygonMain,
    chainName: 'Matic',
    nativeCurrency: {
      name: 'Matic',
      symbol: 'Matic',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.matic.network'],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
};

export const BSC_NETWORKS = [networkChains.PolygonMain];
