import React, {
  FC,
  useMemo,
} from 'react';
import cx from 'classnames';
import RModal, { Props as RModalProps } from 'react-modal';
import styles from './styles.module.scss';

const ROOT = document.getElementById('root');

type ModalProps = {
  onClose: () => void;
} & RModalProps;

const Modal: FC<ModalProps> = ({
  onClose,
  isOpen,
  style,
  portalClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  className,
  appElement,
  onAfterOpen = () => {},
  onAfterClose = () => {},
  closeTimeoutMS,
  aria,
  data,
  role,
  contentLabel,
  contentRef,
  overlayRef,
  testId,
  id,
  children,
  overlayClassName,
}) => {
  const isHeightContentMorePage = useMemo(
    () => document.body.clientHeight - window.innerHeight > 10,
    // Before open modal calc height body (it is hak)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  return (
    <RModal
      isOpen={isOpen}
      style={style}
      portalClassName={portalClassName}
      bodyOpenClassName={cx(bodyOpenClassName)}
      htmlOpenClassName={htmlOpenClassName}
      className={cx(
        styles.modal,
        className,
      )}
      overlayClassName={cx(
        styles.overlay,
        { heightPage: isHeightContentMorePage },
        overlayClassName,
      )}
      appElement={appElement}
      onAfterOpen={() => { document.body.style.overflow = 'hidden'; onAfterOpen(); }}
      onAfterClose={() => { document.body.style.overflow = 'unset'; onAfterClose(); }}
      onRequestClose={onClose}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={false}
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parentSelector={() => ROOT!}
      aria={aria}
      data={data}
      role={role}
      contentLabel={contentLabel}
      contentRef={contentRef}
      overlayRef={overlayRef}
      testId={testId}
      id={id}
    >
      {children}
    </RModal>
  );
};

export { Modal };
