import { RequestStatus, Nft, PartialRecord } from 'types';
import { KrakenState } from 'types/store/KrakenState';
import { KrakenActionTypes } from './actionsTypes';

export const krakenSetState = (payload: Partial<KrakenState>) => ({
  type: KrakenActionTypes.SetState,
  payload,
});

export const krakenSetStatus = (
  payload: { type: KrakenActionTypes, status: RequestStatus },
) => ({
  type: KrakenActionTypes.SetStatus,
  payload,
});

export const krakenSetStatusMint = (
  payload: { status: RequestStatus, nftType: Nft },
) => ({
  type: KrakenActionTypes.SetStatusMint,
  payload,
});

export const krakenGetData = () => ({
  type: KrakenActionTypes.GetData,
});

export const krakenGetDataDisconnect = () => ({
  type: KrakenActionTypes.GetDataDisconnect,
});

export const krakenMint = (amount: number, nftType: Nft, callback?: () => void) => ({
  type: KrakenActionTypes.Mint,
  payload: { amount, nftType, callback },
});

export const krakenStart = () => ({
  type: KrakenActionTypes.Start,
});

export const krakenStop = () => ({
  type: KrakenActionTypes.Stop,
});

export const krakenAddWhiteList = (addressList: string[]) => ({
  type: KrakenActionTypes.AddWhiteList,
  payload: { addressList },
});

export const krakenRemoveWhiteList = (addressList: string[]) => ({
  type: KrakenActionTypes.RemoveWhiteList,
  payload: { addressList },
});

export const krakenChangePrice = (
  listPrices: PartialRecord<Nft, string>, 
  callback?: () => void,
) => ({
  type: KrakenActionTypes.ChangePrice,
  payload: { listPrices, callback },
});
