import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

interface ModalWrapperProps {
  children: ReactNode,
}
const ModalWrapper:FC<ModalWrapperProps> = ({ children }) => (
  <section className={styles.modal_wrapper__container}>
    <div className={styles.almaz} />
    {children}
  </section>
);

export { ModalWrapper };
