import  React from 'react';
import { Modal, Text } from 'components';
import { MultiWalletData } from '../constants/wallet';
import styles from './styles.module.scss'

export default function WalletConnenctModal(props: any) {
  const { modalOpen, handleClose, setWalletName } = props;
  const setWallet = (obj: any) => {
    setWalletName(obj.key);
  };
  return (
    <Modal isOpen={modalOpen} onClose={handleClose}>
        <div className={styles['modal-body-inner']}>
          {MultiWalletData.map((obj) => (
              <div
                onClick={() => setWallet(obj)}
                id={obj.title}
                key={obj.key} 
                className={styles['box-wrapper']}
              >
                  <div className='box-image'>
                    <img src={obj.icon} alt='image' height={50} width={50} />
                  </div>
                  <Text>{obj.title}</Text>
                  <p className='box-description'>{obj.label}</p>
                </div>
          ))}
        </div>
    </Modal>
  );
}
