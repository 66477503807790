import React, { FC } from "react";
import cx from "classnames";
import { PathName, strings } from "appConstants";
import { Text, Image } from "components";
import { Link } from "react-router-dom";
import { nftSmartLogo } from "assets/images";
import styles from "./styles.module.scss";
import { privacyPolicy, terms } from "./constants";
import {
  facebook,
  instagram,
  discord,
  abc,
  telegram,
  twitter,
  tiktok,
} from "assets/images/index";

interface FooterProps {
  className?: string;
  isAdminPath: boolean;
}

const baseSiteUrl = process.env.REACT_APP_NFT_SMART_HILL_BASE_URL;

export const Footer: FC<FooterProps> = ({ className = "", isAdminPath }) => (
  <footer className={cx(styles.footer__container, className)}>
    {/* {
      !isAdminPath && (
        <div className={styles.links}>
          <a
            href={privacyPolicy}
            target="_blank"
            className={styles.textPrivacyPolicy}
            rel="noreferrer"
          >
            {strings.privacyPolicy}
          </a>
          <a
            href={terms}
            target="_blank"
            className={styles.textPrivacyPolicy}
            rel="noreferrer"
          >
            {strings.termsPolicy}
          </a>
        </div>
      )
    } */}

    <div className={styles["grid-container"]}>
      <div className={cx(styles["grid-item"], styles.logoContainer)}>
        <Link to={PathName.store}>
          <Image className={styles.footer__logo} url={nftSmartLogo} />
        </Link>
      </div>
      <div className={cx(styles["grid-item"], styles.urlSec)}>
        <div className={cx(styles["innerDiv"])}>
          <a target="_blank" href={`${baseSiteUrl}/game-play.html`}>
            HOW TO PLAY
          </a>
          <a target="_blank" href={`${baseSiteUrl}/#team`}>
            ABOUT
          </a>
          <a target="_blank" href={`${baseSiteUrl}/careers-vacancies.html`}>
            CAREERS
          </a>
          <a target="_blank" href={`${baseSiteUrl}/index.html#joinus`}>
            COMMUNITY
          </a>
        </div>
        <div className={cx(styles["innerDiv"])}>
          <a target="_blank" href={`https://store.bountytemple.com/`}>
            NFT BUNDLES
          </a>
          <a>MARKETPLACE</a>
          <a target="_blank" href={`${baseSiteUrl}/evolution.html`}>
            P2E-EVOLUTION
          </a>
          <a target="_blank" href={`${baseSiteUrl}/contact.html`}>
            CONTACT US
          </a>
        </div>
      </div>

      <div className={cx(styles["grid-item"], styles.iconsSec)}>
        <div className={cx(styles["innerDivIcon"])}>
          <a target="_blank" href={`https://discord.com/invite/bountytemple`}>
            <img className={cx(styles["imgIcons"])} src={discord} />
          </a>

          <a target="_blank" href={`https://medium.com/@bountytemple`}>
            <img className={cx(styles["imgIcons"])} src={abc} alt="abc" />
          </a>
          <a target="_blank" href={`https://www.tiktok.com/@bountytemple`}>
            <img className={cx(styles["imgIcons"])} src={tiktok} alt="tiktok" />
          </a>
        </div>
        <div className={cx(styles["innerDivIcon"])}>
          <a target="_blank" href={`https://twitter.com/BountyTemple`}>
            <img
              className={cx(styles["imgIcons"])}
              src={twitter}
              alt="twitter"
            />
          </a>
          <a target="_blank" href={`https://www.facebook.com/BountyTempleP2EE`}>
            <img
              className={cx(styles["imgIcons"])}
              src={facebook}
              alt="facebook"
            />
          </a>
        </div>
        <div className={cx(styles["innerDivIcon"])}>
          <a target="_blank" href={`https://t.me/bountytemple`}>
            <img
              className={cx(styles["imgIcons"])}
              src={telegram}
              alt="telegram"
            />
          </a>
          <a target="_blank" href={`https://www.instagram.com/bountytemple/`}>
            <img
              className={cx(styles["imgIcons"])}
              src={instagram}
              alt="instagram"
            />
          </a>
        </div>
      </div>
    </div>

    {!isAdminPath && (
      <Text type="h3" className={cx(styles.footer__admin_copyright)}>
        {strings.copyright}
      </Text>
    )}
  </footer>
);
