import { toast } from 'react-toastify';
import { transformBackendErrorToString } from './transformBackendErrorToString';

export const sagaExceptionHandler = (exception: unknown) => {
  let message = '';
  if (exception instanceof Error || exception instanceof Object) {
    message = transformBackendErrorToString(exception);
  }
  
  if (typeof exception === 'string') {
    message = exception;
  }

  // eslint-disable-next-line no-console
  console.error(exception);

  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
