import 'assets/index.scss';
import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { Preloader } from 'components';
import configureStore from 'store/configureStore';
import { App } from 'app';
import 'react-toastify/dist/ReactToastify.css';

const config = configureStore();
export const { store, persistor, history } = config;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={<Preloader isLoading />}
        persistor={persistor}
      >
        <Router history={history}>
          <App />
          <ToastContainer
            limit={3}
            theme="dark"
          />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
