import createReducer from 'utils/createReducer';
import { Nft, RequestStatus } from 'types';
import { KrakenState } from 'types/store/KrakenState';
import { KrakenActionTypes } from './actionsTypes';
import { krakenHandlers } from './handlers';

export const krakenInitialState: Readonly<KrakenState> = {
  balance: '',
  isMintingInProgress: true,
  isAdmin: false,
  isAddressInWhitelist: true,
  whitelist: [],
  prices: {
    [Nft.Rare]: { 
      supply: '0',
      maxSupply: '0',
      price: '0',
    },
    [Nft.Epic]: { 
      supply: '0',
      maxSupply: '0',
      price: '0',
    },
    [Nft.Legendary]: { 
      supply: '0',
      maxSupply: '0',
      price: '0',
    },
    [Nft.Mystic]: { 
      supply: '0',
      maxSupply: '0',
      price: '0',
    },
  },
  ui: {
    [KrakenActionTypes.GetData]: RequestStatus.INIT,
    [KrakenActionTypes.GetDataDisconnect]: RequestStatus.INIT,
    [KrakenActionTypes.Mint]: RequestStatus.INIT,
    [KrakenActionTypes.Start]: RequestStatus.INIT,
    [KrakenActionTypes.Stop]: RequestStatus.INIT,
    [KrakenActionTypes.AddWhiteList]: RequestStatus.INIT,
    [KrakenActionTypes.RemoveWhiteList]: RequestStatus.INIT,
    [KrakenActionTypes.ChangePrice]: RequestStatus.INIT,
  },
  mintStatus: {
    [Nft.Epic]: RequestStatus.INIT,
    [Nft.Rare]: RequestStatus.INIT,
    [Nft.Legendary]: RequestStatus.INIT,
    [Nft.Mystic]: RequestStatus.INIT,
  },
};

export default createReducer(krakenInitialState, krakenHandlers);
