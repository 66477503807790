import { BigNumber } from 'bignumber.js';
import { getAddress, getContract } from 'api/ethereum';
import { contracts, MAX_UINT_256, decimalPlaces } from 'appConstants';
import { UsdtAbi } from 'assets/abi';
import { getUSDTContract } from 'api/USDTContract';
import { ContractTransaction } from 'ethers';

export const apiGetDecimalsUsdtToken = async (): Promise<number> => {
  const contract = getContract(contracts.Usdt, UsdtAbi);
  const result = await contract.methods.decimals().call();
  return result;
};

export const apiApproveUsdt = async (
  addressSpender: string,
  stakeAmount: string,
) => {
  const contract = getUSDTContract();
  if (!contract) {
    throw new Error('undefined USDTContract');
  }
  const address = await getAddress();
  const tx: ContractTransaction =
    await contract.approve(addressSpender, stakeAmount, { from: address });
  const txn = await tx.wait();
  return txn;
};

export const apiApproveUsdtToKrakenMax = async () => apiApproveUsdt(
  contracts.Kraken,
  MAX_UINT_256,
);

export const apiAllowanceUsdt = async (
  addressSpender: string,
): Promise<string> => {
  const contract = getUSDTContract();
  if (!contract) {
    throw new Error('undefined USDTContract');
  }
  const address = await getAddress();

  const result = await contract.allowance(address, addressSpender);
  const decimals = await apiGetDecimalsUsdtToken();

  return (
    new BigNumber(result.toString())
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces).toString()
  );
};

export const apiAllowanceUsdtToKraken = async (): Promise<string> =>
  apiAllowanceUsdt(contracts.Kraken);

export const apiGetBalanceUsdt = async (): Promise<string> => {
  const contract = getUSDTContract();
  if (!contract) {
    throw new Error('undefined USDTContract');
  }
  const address = await getAddress();

  const result = await contract.balanceOf(address);

  const decimals = await apiGetDecimalsUsdtToken();

  return (
    new BigNumber(result.toString())
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces).toString()
  );
};
