import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from 'components';
import { SaleStatus, Whitelisted, Settings, AddIpfsUri } from 'containers';
// import { metamaskSelectors } from 'store/metamask/selectors';
// import { metamaskConnect } from 'store/metamask/actionCreators';
// import { krakenGetDataDisconnect, krakenGetData } from 'store/kraken/actionCreators';
import styles from './styles.module.scss';
import { krakenSelectors } from 'store/kraken/selectors';

import { krakenSetState } from 'store/kraken/actionCreators';
import { getKrakenContractFromProvider } from 'api/krakenContract';

export const Admin: FC = () => {
  const dispatch = useDispatch();
  const { address } = useSelector((state: any) => state.wallet);

  const isAdmin = useSelector(krakenSelectors.getProp('isAdmin'));

  // const address = useSelector(metamaskSelectors.getProp('address'));
  // const isLostWallet = useSelector(metamaskSelectors.getProp('isLostWallet'));

  // useEffect(() => {
  //   dispatch(krakenGetDataDisconnect());
  //   if (!isLostWallet) {
  //     dispatch(metamaskConnect());
  //   }
  // }, [dispatch, isLostWallet]);

  // useEffect(() => {
  //   dispatch(krakenGetDataDisconnect());
  //   if (address) {
  //     dispatch(krakenGetData());
  //   }
  // }, [address, dispatch]);

  const getKrakenState = async () => {
    const contract = getKrakenContractFromProvider(process.env.REACT_APP_POLYGON_RPC_URL as string);
    const admin = await contract.methods.admin().call();
    const balance = await contract.methods.balanceOf(address).call();

    console.log(admin, balance);
    dispatch(
      krakenSetState({
        balance,
        isAdmin: address.toLowerCase() === admin.toLowerCase(),
        isAddressInWhitelist: false,
      })
    );
  };

  useEffect(() => {
    if (address) getKrakenState();
  }, [address]);

  return (
    <div className={styles.container}>
      {isAdmin ? (
        <>
          {' '}
          <Text className={styles.title}>Admin Panel</Text>
          <SaleStatus className={styles.status} />
          <Settings className={styles.settings} />
      <div className={styles.whitelist}><Whitelisted /></div>
      <AddIpfsUri className={styles.ipfs} />
        </>
      ) : (
        <Text className={styles.title}>You are not an admin!!!</Text>
      )}
    </div>
  );
};
