// Action types
const WALLET_CREDENTIALS = 'user/walletCredentials';

export const walletCredentials = (payload: {
  userConnected: boolean;
  connectedWallet: string;
}) => ({
  type: WALLET_CREDENTIALS,
  payload,
});

// Reducer
interface IState {
  userConnected: boolean;
  connectedWallet: string;
}

const initialState: IState = {
  userConnected: false,
  connectedWallet: '',
};

const userReducer = (state: IState = initialState, action: any) => {
  switch (action.type) {
    case WALLET_CREDENTIALS:
      return {
        ...state,
        userConnected: action.payload.userConnected,
        connectedWallet: action.payload.connectedWallet,
      };

    default:
      return state;
  }
};

export default userReducer;
