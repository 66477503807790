import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { 
  apiPricesKraken, 
  apiMintingInProgressKraken, apiGetWhitelistKraken,
} from 'api';
import {
  RequestStatus, Unwrap, 
  Nft,
} from 'types';
import { 
  krakenSetStatus, krakenGetDataDisconnect, krakenSetState, krakenGetData,
} from 'store/kraken/actionCreators';

export function* krakenGetDataDisconnectSaga({ type }: ReturnType<typeof krakenGetDataDisconnect>) {
  try{
    yield put(krakenSetStatus({ type, status: RequestStatus.REQUEST }));

    const prices: Record<Nft, Unwrap<typeof apiPricesKraken>> = {
      [Nft.Rare]: yield call(apiPricesKraken, Nft.Rare), 
      [Nft.Epic]: yield call(apiPricesKraken, Nft.Epic), 
      [Nft.Legendary]: yield call(apiPricesKraken, Nft.Legendary), 
      [Nft.Mystic]: yield call(apiPricesKraken, Nft.Mystic), 
    };

    const isMintingInProgress: Unwrap<typeof apiMintingInProgressKraken> = 
      yield call(apiMintingInProgressKraken);
    
    const whitelist: Unwrap<typeof apiGetWhitelistKraken> = 
      yield call(apiGetWhitelistKraken);

    yield put(krakenSetState({ 
      prices, 
      isMintingInProgress, 
      whitelist: whitelist.map((address) => address.toLocaleLowerCase()),
    }));
    
    yield put(krakenGetData());
    
    yield put(krakenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(krakenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
