import { put } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { apiChangeMintPriceKraken } from 'api';
import {
  RequestStatus, Nft,
} from 'types';
import { 
  krakenSetStatus, krakenGetDataDisconnect, krakenChangePrice,
} from 'store/kraken/actionCreators';

export function* krakenChangePriceSaga({
  type, payload: { listPrices, callback },
}: ReturnType<typeof krakenChangePrice>) {
  try{
    yield put(krakenSetStatus({ type, status: RequestStatus.REQUEST }));

    const arrayPrices = Object.keys(listPrices).filter(
      (key) => listPrices[key as Nft]?.length,
    );

    yield Promise.all(arrayPrices.map(async (typePrice: string) =>
      apiChangeMintPriceKraken(
        typePrice as Nft, 
        listPrices[typePrice as Nft] as string,
      )));

    yield put(krakenGetDataDisconnect());
    if (callback) callback();
      
    yield put(krakenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(krakenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
