import { BigNumber } from 'bignumber.js';
import { getAddress, getContract, apiGetDecimalsUsdtToken } from 'api/ethereum';
import { Nft } from 'types';
import { KrakenAbi } from 'assets/abi';
import { contracts, decimalPlaces } from 'appConstants';
import { getKrakenContract } from 'api/krakenContract';
import { ContractTransaction } from 'ethers';

export const apiGetNftTiesKraken = async (nftType: Nft): Promise<string> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);
  if (nftType === Nft.Epic) {
    return contract.methods
      .EPIC()
      .call();
  }
  if (nftType === Nft.Legendary) {
    return contract.methods
      .LEGENDARY()
      .call();
  }
  if (nftType === Nft.Mystic) {
    return contract.methods
      .MYSTIC()
      .call();
  }
  return contract.methods
    .RARE()
    .call();
};

export const apiMintKraken = async (amount: number, nftType: Nft) => {
  const contract = getKrakenContract();
  if (!contract) {
    throw new Error('undefined Contract');
  }
  const address = await getAddress();
  const tier = await apiGetNftTiesKraken(nftType);
  const tx: ContractTransaction = await contract.mint(amount, tier, { from: address });
  const txn = await tx.wait();
  return txn;
};

export const apiStartMintingKraken = async () => {
  const contract = getKrakenContract();
  if (!contract) {
    throw new Error('undefined Contract');
  }
  const address = await getAddress();
  const tx: ContractTransaction = await contract.startMinting({ from: address });
  const txn = await tx.wait();
  return txn;
};

export const apiStopMintingKraken = async () => {
  const contract = getKrakenContract();
  if (!contract) {
    throw new Error('undefined Contract');
  }
  const address = await getAddress();
  const tx: ContractTransaction = await contract.stopMinting({ from: address });
  const txn = await tx.wait();
  return txn;
};

export const apiAddToWhitelistKraken = async (users: string[]) => {
  const contract = getKrakenContract();
  if (!contract) {
    throw new Error('undefined Contract');
  }
  const address = await getAddress();
  const tx: ContractTransaction = await contract.addToWhitelist(users, { from: address });
  const txn = await tx.wait();
  return txn;
};

export const apiRemoveFromWhitelistKraken = async (users: string[]) => {
  const contract = getKrakenContract();
  if (!contract) {
    throw new Error('undefined Contract');
  }
  const address = await getAddress();
  const tx: ContractTransaction = await contract.removeFromWhitelist(users, { from: address });
  const txn = await tx.wait();
  return txn;
};

export const apiChangeMintPriceKraken = async (nftType: Nft, newPrice: string) => {
  const contract = getContract(contracts.Kraken, KrakenAbi);
  const address = await getAddress();
  const tier = await apiGetNftTiesKraken(nftType);
  const decimals = await apiGetDecimalsUsdtToken();

  const price = new BigNumber(newPrice)
    .multipliedBy(new BigNumber(10).pow(decimals))
    .toString();

  return contract.methods
    .changeMintPrice(tier, price)
    .send({ from: address });
};

export const apiTokenUriKraken = async (idToken: number): Promise<string> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);

  return contract.methods
    .tokenUri(idToken)
    .call();
};

export const apiBalanceKraken = async (): Promise<string> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);
  const address = await getAddress();

  return contract.methods
    .balanceOf(address)
    .call();
};

export const apiGetIsAdminKraken = async (): Promise<boolean> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);
  const address = await getAddress();
  const adminAddress = await contract.methods
    .admin()
    .call();

  return address === adminAddress;
};

export const apiBaseUriKraken = async (): Promise<string> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);

  return contract.methods
    .baseURI()
    .call();
};

export const apiMintingInProgressKraken = async (): Promise<boolean> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);

  return contract.methods
    .mintingInProgress()
    .call();
};

export const apiPricesKraken = async (nftType: Nft): Promise<{
  supply: string,
  maxSupply: string,
  price: string,
}> => {
  const contract = getKrakenContract();
  if (!contract) {
    throw new Error('undefined Contract');
  }
  const tier = await apiGetNftTiesKraken(nftType);

  const res = await contract.tiers(tier);
  const decimals = await apiGetDecimalsUsdtToken();
  return {
    ...res,
    price: new BigNumber((res.price).toString())
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces).toString(),
  };
};

export const apiGetWhitelistKraken = async (): Promise<string[]> => {
  const contract = getContract(contracts.Kraken, KrakenAbi);

  return contract.methods
    .getWhitelist()
    .call();
};
