import React, { useState, useEffect } from 'react';
import { Modal } from 'components';

import style from './switchChain.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { setChainErrorMsg } from '../../../store/web3/selectedNetwork';
import {
  setChainID,
  setModalNetwork,
  setAddress,
} from '../../../store/web3/walletSlice';

import {
  numberToHexadecimal,
  getSampleChain,
  SupportedChainId,
} from '../constants/chain';

import { walletCredentials } from '../../../store/web3/connectedUserInfo';
import { setProvider } from '../../../store/web3/provider';
import {
  dataSwitchChainTestnet,
  dataSwitchChainMainnet,
} from '../constants/chain';
import { SupportedWallets } from '../constants/wallet';
import { useWeb3React } from '@web3-react/core';
import { toast } from 'react-toastify';

const MultiWallet = () => {
  const [clientOnly, setClientOnly] = useState(false);
  const dataSwitchChain =
    process.env.REACT_APP_NETWORK_TYPE === 'testnet'
      ? dataSwitchChainTestnet
      : dataSwitchChainMainnet;
  const dispatch = useDispatch();

  const { connector } = useWeb3React();

  const { userConnected, connectedWallet } = useSelector(
    (state: any) => state.user
  );

  const { provider } = useSelector((state: any) => state.providerReducer);

  const { errorMessage } = useSelector((state: any) => state.network);

  const { isModalNetwork, chainId } = useSelector((state: any) => state.wallet);

  const switchNetwork = async (chainKey: any) => {
    if (chainKey !== chainId) {
      if (userConnected) {
        try {
          if (SupportedWallets.walletconnect === connectedWallet) {
            if (chainKey === 1 || chainKey === 5) {
              return toast.error(
                'Something went wrong while switching chain. Please switch it manually from your wallet.'
              );
            }
            try {
              const res = await provider?.request({
                method: 'wallet_addEthereumChain',
                params: [getSampleChain(chainKey)],
              });
              if (res) {
                dispatch(setChainID(chainKey));
                dispatch(setModalNetwork(false));
                dispatch(setChainErrorMsg(''));
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            try {
              await provider?.request({
                method: 'wallet_switchEthereumChain',
                params: [
                  {
                    chainId: numberToHexadecimal(chainKey),
                  },
                ],
              });
              dispatch(setChainID(chainKey));
              dispatch(setModalNetwork(false));
              dispatch(setChainErrorMsg(''));
            } catch (err) {
              console.log(err);
            }
          }
        } catch (switchError: any) {
          if (
            switchError.code === 4902 ||
            String(switchError).includes('wallet_addEthereumChain')
          ) {
            try {
              await provider?.request({
                method: 'wallet_addEthereumChain',
                params: [getSampleChain(chainKey)],
              });
              switchNetwork(chainKey);
              dispatch(setChainErrorMsg(''));
            } catch (err: any) {
              if (err.code === 4001) {
              }
            }
          } else if (
            String(switchError).includes('request is not a function')
          ) {
            if (connector) {
              connector?.deactivate
                ? connector?.deactivate()
                : connector.resetState();
            }
            localStorage.removeItem('walletconnect');
            dispatch(
              walletCredentials({
                userConnected: false,
                connectedWallet: '',
              })
            );
            dispatch(setAddress(undefined));
            dispatch(
              setProvider({
                provider: {},
                address: '',
              })
            );
            localStorage.removeItem('token');
            localStorage.removeItem('refreshtoken');
            localStorage.clear();

            dispatch(setChainID(chainKey));
            dispatch(setModalNetwork(false));
            dispatch(setChainErrorMsg(''));
          }
        }
      } else {
        dispatch(setChainID(chainKey));
        dispatch(setModalNetwork(false));
        dispatch(setChainErrorMsg(''));
      }
    }
  };

  const hideChainModal = () => {
    if (!isActiveChain(chainId)) {
      return;
    }
    dispatch(setChainErrorMsg(''));
    dispatch(setModalNetwork(false));
  };

  function isActiveChain(chainId?: SupportedChainId | number | string) {
    for (let i = 0; i < dataSwitchChain.length; i++) {
      if (dataSwitchChain[i].key === Number(chainId)) return true;
    }

    return false;
  }

  useEffect(() => setClientOnly(true), []);

  return (
    <>
      <Modal
        isOpen={clientOnly && (isModalNetwork || !isActiveChain(chainId))}
        onClose={() => hideChainModal()}
      >
        {/* <ModalWrapper> */}
          <div className={style['mainContainer']}>
          <p className={style['infoMsg']}>
            *Switch the Blockchain type so that the Wallet and the URL are{' '}
            <br className={style['br-pc']} />
            unified and connected to same Network chain.
          </p>
          <span className={`${style['switchNetwork_error']} text-center mb-3`}>
            {errorMessage}
          </span>
          <div className={style['switchNetwork_box-wrp']}>
            <div className={style['switchNetwork_box']}>
              {dataSwitchChain.map((chain, i) => (
                <div className={style['switchNetwork_items']} key={i}>
                  <div
                    className={`
                            ${style['switchNetwork_ico']} 
                            ${chain.key === Number(focus || 0) ? 'focus' : ''}
                            ${
                              chain.key === Number(chainId || 0) ? 'active' : ''
                            }
                          `}
                    key={i}
                    onClick={() => switchNetwork(chain.key)}
                  >
                    <img src={chain.logo} alt='Eth icon' />

                    <p className='heading--5'>{chain.title}</p>
                    <span className={style['checked']}>
                      <img
                        src='https://ik.imagekit.io/xanalia/xana/checked-ico.svg'
                        alt='checked'
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          </div>
        {/* </ModalWrapper> */}
      </Modal>
    </>
  );
};
export default MultiWallet;
