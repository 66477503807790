import { SupportedWallets } from './constants/wallet';
import { SupportedChainId } from './constants/chain';

export function getWalletType(): SupportedWallets | undefined {
  if (typeof window === 'undefined') return undefined;

  const { walletType } = window as any;

  if (!walletType) {
    let key = localStorage.getItem('walletType') as
      | SupportedWallets
      | undefined;
    key = key === null ? undefined : key;
    setWalletType(key);
    return key;
  }

  return walletType;
}

export function setWalletType(type?: SupportedWallets) {
  if (typeof window === 'undefined') return;
  (window as any).walletType = type;

  if (!type) {
    localStorage.removeItem('walletType');
  } else {
    localStorage.setItem('walletType', type);
  }
}

export function getChainId(): SupportedChainId | undefined {
  if (typeof window === 'undefined') return undefined;

  const { chainId } = window as any;

  if (!chainId) {
    const key = localStorage.getItem('chainId');
    const result =
      key === null ? undefined : (Number(key) as SupportedChainId | undefined);
    setChainId(result);
    return result;
  }

  return chainId;
}

export function setChainId(type?: SupportedChainId) {
  if (typeof window === 'undefined') return;
  (window as any).chainId = type;
  localStorage.setItem('chainId', String(type) || '');
}
