import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { apiMintKraken } from 'api';
import {
  RequestStatus,
} from 'types';
import { 
  krakenSetStatusMint, krakenGetData, krakenMint,
} from 'store/kraken/actionCreators';

export function* krakenMintSaga({
  payload: { amount, nftType, callback },
}: ReturnType<typeof krakenMint>) {
  try{
    yield put(krakenSetStatusMint({ nftType, status: RequestStatus.REQUEST }));

    yield call(apiMintKraken, amount, nftType);

    yield put(krakenGetData());

    if (callback) callback();

    yield put(krakenSetStatusMint({ nftType, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(krakenSetStatusMint({ nftType, status: RequestStatus.ERROR }));
  }
}
