import { takeLeading } from 'redux-saga/effects';
import { KrakenActionTypes } from '../actionsTypes';
import { krakenGetDataSaga } from './getData';
import { krakenMintSaga } from './mint';
import { krakenGetDataDisconnectSaga } from './getDataDisconnect';
import { krakenStartSaga } from './start';
import { krakenStopSaga } from './stop';
import { krakenAddWhiteListSaga } from './addWhiteList';
import { krakenRemoveWhiteListSaga } from './removeWhiteList';
import { krakenChangePriceSaga } from './changePrice';

export default function* krakenSaga() {
  yield takeLeading(KrakenActionTypes.GetData, krakenGetDataSaga);
  yield takeLeading(KrakenActionTypes.Mint, krakenMintSaga);
  yield takeLeading(KrakenActionTypes.GetDataDisconnect, krakenGetDataDisconnectSaga);
  yield takeLeading(KrakenActionTypes.Start, krakenStartSaga);
  yield takeLeading(KrakenActionTypes.Stop, krakenStopSaga);
  yield takeLeading(KrakenActionTypes.AddWhiteList, krakenAddWhiteListSaga);
  yield takeLeading(KrakenActionTypes.RemoveWhiteList, krakenRemoveWhiteListSaga);
  yield takeLeading(KrakenActionTypes.ChangePrice, krakenChangePriceSaga);
}
