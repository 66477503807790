// Action types
const SET_PROVIDER = 'provider/setProvider';

// Action creators
export const setProvider = (payload: any) => ({
  type: SET_PROVIDER,
  payload,
});

// Reducer
const initialState = {
  provider: {},
  address: '',
};

const providerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PROVIDER:
      return {
        ...state,
        address: action.payload.address,
        provider: action.payload.provider,
      };

    default:
      return state;
  }
};

export default providerReducer;
