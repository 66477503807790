import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { apiAddToWhitelistKraken } from 'api';
import {
  RequestStatus,
} from 'types';
import { 
  krakenSetStatus, krakenGetDataDisconnect, krakenAddWhiteList,
} from 'store/kraken/actionCreators';

export function* krakenAddWhiteListSaga({
  type, payload: { addressList },
}: ReturnType<typeof krakenAddWhiteList>) {
  try{
    yield put(krakenSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(apiAddToWhitelistKraken, addressList);

    yield put(krakenGetDataDisconnect());

    yield put(krakenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(krakenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
