/* eslint-disable no-unused-vars */

import { ethIcon } from 'assets/images';
import { polygonIcon } from 'assets/images';

export const numberToHexadecimal = (value: number | string) => {
  return '0x' + Number(value).toString(16);
};

const RPC_ETHEREUM_TESTNET =
  'https://eth-sepolia.g.alchemy.com/v2/wjij-iNz2B8n7rN0JgKUdpPS7txwkF50';
const RPC_POLYGON_TESTNET = 'https://rpc-amoy.polygon.technology/';
const RPC_ETHEREUM_MAINNET =
  'https://mainnet.infura.io/v3/68026010151145c9b2de3c9889f09067';
const RPC_POLYGON_MAINNET =
  'https://polygon-mainnet.infura.io/v3/68026010151145c9b2de3c9889f09067';

const CHAINID_ETHEREUM =
  process.env.REACT_APP_NETWORK_TYPE === 'testnet' ? 11155111 : 1;
const CHAINID_POLYGON =
  process.env.REACT_APP_NETWORK_TYPE === 'testnet' ? 80002 : 137;

export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 11155111,
  POLYGON = 137,
  POLYGON_MUMBAI = 80002,
}

export interface AddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export const getSampleChain = (
  chainID: SupportedChainId | number | string
): AddEthereumChainParameter | undefined => {
  if (Number(chainID) === SupportedChainId.MAINNET) {
    return {
      chainId: numberToHexadecimal(1),
      chainName: 'ETHER',
      nativeCurrency: {
        name: 'ETHER',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [RPC_ETHEREUM_MAINNET],
      blockExplorerUrls: ['https://etherscan.io'],
    };
  }

  if (Number(chainID) === SupportedChainId.GOERLI) {
    return {
      chainId: numberToHexadecimal(11155111),
      chainName: 'Sepolia',
      nativeCurrency: {
        name: 'Goerli',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [RPC_ETHEREUM_TESTNET],
      blockExplorerUrls: ['https://sepolia.etherscan.io'],
    };
  }

  if (Number(chainID) === SupportedChainId.POLYGON) {
    return {
      chainId: numberToHexadecimal(137),
      chainName: 'POLYGON',
      nativeCurrency: {
        name: 'POLYGON',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [RPC_POLYGON_MAINNET],
      blockExplorerUrls: ['https://polygonscan.com'],
    };
  }

  if (Number(chainID) === SupportedChainId.POLYGON_MUMBAI) {
    return {
      chainId: numberToHexadecimal(80002),
      chainName: 'Polygon Amoy Testnet',
      nativeCurrency: {
        name: 'AMOY MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [RPC_POLYGON_TESTNET],
      blockExplorerUrls: ['https://amoy.polygonscan.com/'],
    };
  }

  return undefined;
};

export const dataSwitchChainMainnet = [
  {
    key: CHAINID_POLYGON,
    logo: polygonIcon,
    title: 'Polygon',
    rpc: RPC_POLYGON_MAINNET,
    chainID: CHAINID_POLYGON.toString(),
  },
  {
    key: CHAINID_ETHEREUM,
    logo: ethIcon,
    title: 'Ethereum',
    rpc: RPC_ETHEREUM_MAINNET,
    chainID: CHAINID_ETHEREUM.toString(),
  },
];

export const dataSwitchChainTestnet = [
  {
    key: CHAINID_POLYGON,
    logo: polygonIcon,
    title: 'Polygon',
    rpc: RPC_POLYGON_TESTNET,
    chainID: CHAINID_POLYGON.toString(),
  },
  {
    key: CHAINID_ETHEREUM,
    logo: ethIcon,
    title: 'Ethereum',
    rpc: RPC_ETHEREUM_TESTNET,
    chainID: CHAINID_ETHEREUM.toString(),
  },
];
