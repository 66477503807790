import auth from './auth';
import kraken from './kraken';
import usdt from './usdt';
import metamask from './metamask';
import user from './web3/connectedUserInfo';
import providerReducer from './web3/provider';
import network from './web3/selectedNetwork';
import wallet from './web3/walletSlice';

export default {
  auth,
  kraken,
  usdt,
  metamask,
  user,
  providerReducer,
  network,
  wallet,
};
