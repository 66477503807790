import { RequestStatus } from 'types';
import { UsdtState } from 'types/store/UsdtState';
import { UsdtActionTypes } from './actionsTypes';

export const usdtSetState = (payload: Partial<UsdtState>) => ({
  type: UsdtActionTypes.SetState,
  payload,
});

export const usdtSetStatus = (
  payload: { type: UsdtActionTypes, status: RequestStatus },
) => ({
  type: UsdtActionTypes.SetStatus,
  payload,
});

export const usdtGetData = () => ({
  type: UsdtActionTypes.GetData,
});

export const usdtApprove = () => ({
  type: UsdtActionTypes.Approve,
});
