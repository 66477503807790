/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { checkedIcon } from 'assets/images';
import React, { memo, useCallback } from 'react';

type RadioBuuttonElementProps = {
  isChecked: boolean,
  index: number,
  value: string,
  onSelect: (index: number, value: string) => void,
  classNameElement: string,
  classNameElementSelected: string,
  classNameElementBox: string,
};

const Checkbox = memo(({
  isChecked,
  index,
  value,
  onSelect,
  classNameElement,
  classNameElementSelected,
  classNameElementBox,
}: RadioBuuttonElementProps) => {
  const onRadioButtonClick = useCallback(() => {
    onSelect(index, value);
  }, [onSelect, index, value]);

  return (
    <div
      className={classNameElementBox}
      onClick={onRadioButtonClick}
      role="radio"
      tabIndex={0}
    >
      <div
        className={
          isChecked
            ? classNameElementSelected
            : classNameElement
        }
      >
        {isChecked && (
        <img
          src={checkedIcon}
          alt="checked"
        />
        )}
      </div>
      <p>{value}</p>
    </div>
  );
});

export { Checkbox };
