import { initializeConnector } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import {
  dataSwitchChainMainnet,
  dataSwitchChainTestnet,
} from './constants/chain';

const CHAINS =
  process.env.REACT_APP_NETWORK_TYPE === 'testnet'
    ? dataSwitchChainTestnet
    : dataSwitchChainMainnet;

const [...optionalChains] = CHAINS.map((chain: any) => Number(chain.chainID));

const rpcObjs: any = {};
for (let i = 0; i < CHAINS.length; i+=1) {
  rpcObjs[Number(CHAINS[i].chainID)] = CHAINS[i].rpc;
}

export const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_KEY as string,
        optionalChains,
        showQrModal: true,
        rpcMap: {
          ...rpcObjs,
        },
      },
    }),
);
