import { apiInterceptors } from 'api';
import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { State } from 'types/store';
import reducer from './rootReducer';
import rootSaga from './rootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const metamaskPersistConfig = {
  key: 'metamask',
  storage,
  whitelist: ['isLostWallet'] as Array<keyof State['metamask']>,
};

const userPersistConfig = {
  key: 'user',
  storage,
  // whitelist:['user']
};
const providerPersistConfig = {
  key: 'provider',
  storage,
  blacklist: ['provider'],
};
const networkPersistConfig = {
  key: 'network',
  storage,
  // whitelist:['user'],
};
const walletPersistConfig = {
  key: 'wallet',
  storage,
  // whitelist:['user'],
};

const reducers = {
  router: routerReducer,
  ...reducer,
  metamask: persistReducer(metamaskPersistConfig, reducer.metamask),
  user: persistReducer(userPersistConfig, reducer.user),
  providerReducer: persistReducer(providerPersistConfig, reducer.providerReducer),
  network: persistReducer(networkPersistConfig, reducer.network),
  wallet: persistReducer(walletPersistConfig, reducer.wallet),
};

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
    __REDUX_DEVTOOLS_EXTENSION__: typeof compose;
  }
}

export default (initialState: { [key: string]: never } = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    || window.__REDUX_DEVTOOLS_EXTENSION__
    || compose;

  const store = createStore(
    combineReducers(reducers),
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware,
      ),
    ),
  );

  const history = createReduxHistory(store);

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);
  apiInterceptors(store);

  return { store, persistor, history };
};
