import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { strings } from "appConstants";
import { openseaImage } from "assets/images";
import { Nft } from "types";

import axios from "axios";
import { toast } from "react-toastify";
import { Loader, CountDown } from "components";
import { BigNumber } from "bignumber.js";
import { KrakenAbi, UsdtAbi } from "assets/abi/index";
import { ethers } from "ethers";
import { contracts } from "appConstants/contracts";
import { openseaLink } from "./constants";
import { Card } from "./packCard/packCard";
import TraitRewards from "./traitRewards/traitRewards";
import JoinUs from "assets/images/JoinUs.png";
import styles from "./styles.module.scss";

const backendUrl = process.env.REACT_APP_API_URL;

const Store: FC = () => {
  const userConnected = useSelector((state: any) => state.user.userConnected);
  const { address } = useSelector((state: any) => state.wallet);
  const { provider } = useSelector((state: any) => state.providerReducer);
  const { currentNetwork } = useSelector((state: any) => state.network);

  const [showLoader, setShowLoader] = useState(true);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const [saleStarted, setSaleStarted] = useState(false);
  const [gettingSaleStartStatus, setGettingSaleStartStatus] = useState(true);
  const [publicSaleStarted, setPublicSaleStarted] = useState(false);
  const [showSaleStartedTimer, setShowSaleStartedTimer] = useState(false);

  const [oGMint, setOGMint] = useState(false);
  const [oGWhitelistMinted, setOGWhitelistMinted] = useState(false);
  const [oGWhiteListed, setIsOGWhiteListed] = useState(false);
  const [normalWhiteListed, setIsNormalWhiteListed] = useState(false);
  const [userNtfsBalance, setNftsBalance] = useState(0);
  const [ethPrice, setEthPrice] = useState(1);

  const [cards, setCards] = useState([]);
  const [buttonLoaders, setButtonLoaders] = useState({
    Rare: false,
    Epic: false,
    Legendary: false,
    Mystic: false,
  });

  const isPublicSaleStarted = async () => {
    const ethProvider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_POLYGON_RPC_URL
    );
    const tradeContractInstance = new ethers.Contract(
      contracts.Kraken,
      KrakenAbi,
      ethProvider
    );
    try {
      const res = await tradeContractInstance.whiteListEnabled();
      // console.log(res);
      setPublicSaleStarted(!res);
    } catch (err) {
      console.log(err);
    }
  };

  const checkIsSaleStarted = async () => {
    const ethProvider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_POLYGON_RPC_URL
    );
    const tradeContractInstance = new ethers.Contract(
      contracts.Kraken,
      KrakenAbi,
      ethProvider
    );
    try {
      const res = await tradeContractInstance.mintingInProgress();
      // console.log(res);
      setSaleStarted(res);
      setGettingSaleStartStatus(false);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong while getting sale status.");
    }
  };

  const fetchPackData = async () => {
    try {
      const tiers = (await axios.get(backendUrl + "/contract/getTiersInfo"))
        .data;

      let cardData: any = [];
      tiers?.data.forEach((tier: any) => {
        cardData.push({
          title: tier.name + " PACK",
          key: tier.name.slice(0, 1) + tier.name.slice(1).toLowerCase(),
          val: 1,
          price: tier.tierNftPrice,
          rarity: tier.rarity,
          tierSupplyCount: tier.tierSupplyCount,
          tierMintedCount: tier.tierMintedCount,
          ethPrice: tier.tierNftPrice / ethPrice,
        });
      });
      setCards(cardData);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong while fetching packs data.");
    } finally {
      setShowLoader(false);
    }
  };

  // console.log(address, provider);

  const getUserWhiteListInfo = async () => {
    const userInfoRes = (
      await axios.post(backendUrl + "/contract/getUserInfo", {
        walletAddress: address,
      })
    ).data;
    const {
      isOGWhiteListed,
      isNormalWhiteListed,
      ntfsBalance,
      OGWhitelistMinted,
    } = userInfoRes.data;

    setIsOGWhiteListed(isOGWhiteListed);
    setIsNormalWhiteListed(isNormalWhiteListed);
    setNftsBalance(ntfsBalance);
    setOGWhitelistMinted(OGWhitelistMinted);

    if (isOGWhiteListed && !OGWhitelistMinted) {
      setOGMint(true);
    } else {
      setOGMint(false);
    }
  };

  const resetState = () => {
    setOGMint(false);
    setOGWhitelistMinted(false);
    setIsOGWhiteListed(false);
    setIsNormalWhiteListed(false);
    setNftsBalance(0);
  };

  const getEthPrice = async () => {
    try {
      const res: any = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      if (res?.status === 200 && res?.data?.ethereum?.usd) {
        setEthPrice(res?.data?.ethereum?.usd);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEthPrice();
    checkIsSaleStarted();
  }, []);

  useEffect(() => {
    if (saleStarted) {
      isPublicSaleStarted();
      fetchPackData();
    }
  }, [saleStarted]);

  useEffect(() => {
    if (address) {
      getUserWhiteListInfo();
    } else {
      resetState();
    }
  }, [address]);

  // console.log(cards);

  const putLoaderOnButton = (key: string, val: boolean) => {
    setIsDisabledButton(val);
    setButtonLoaders((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const mintTokensOnPolygonWithSignature = async (
    rarity: string,
    key: string,
    val: number
  ) => {
    try {
      // calling backend API for signature
      const signature = (
        await axios.post(backendUrl + "/contract/generateSignature", {
          mintToAddress: address,
          whiteListType: "Normal",
        })
      ).data;
      let signatureTuple = signature.data;
      const ethProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethProvider.getSigner();

      const contractInstance = new ethers.Contract(
        contracts.Kraken,
        KrakenAbi,
        signer
      );
      const mintTrx = await contractInstance.mintNFTsNormalWhitelist(
        signatureTuple,
        val,
        rarity
      );

      toast.success(
        "Transaction submitted to network! Wait for transcation to confirm"
      );
      // window.open(
      //   import.meta.env.VITE_POLYGON_EXPLORER_URL + 'tx/' + mintTrx.hash,
      //   '_blank'
      // );
      const trxReceipt = await mintTrx.wait(1);
      console.log("trxReceipt", trxReceipt);
      toast.success("Minting Free NFT Successful");
      putLoaderOnButton(key, false);
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (err: any) {
      if (err.code === "ACTION_REJECTED") {
        toast.error("You rejected request from wallet... Try Again!");
        putLoaderOnButton(key, false);
      } else {
        console.error("Error in mintTokensOnPolygonWithSignature", err);
        toast.error("Something went wrong..!");
        putLoaderOnButton(key, false);
      }
    }
  };

  const mintNFTs = async (
    rarity: string,
    key: string,
    val: number,
    price: number
  ) => {
    let errorMessage = "";
    putLoaderOnButton(key, true);
    const ethProvider = new ethers.providers.Web3Provider(provider);
    const signer = ethProvider.getSigner();

    // Checking if connected to correct network
    if (currentNetwork.title === "Ethereum") {
      // Initiaitng Etherum Payment Flow
      try {
        // Request message from backend for user to sign
        const messageRes = (
          await axios.post(backendUrl + "/v1/requestMessage", {
            walletAddress: address,
          })
        ).data;
        if (messageRes.error) {
          errorMessage = messageRes.message;
          throw new Error("12345");
        }
        let messageToSign = JSON.stringify(messageRes.data.messageToSign);
        // Sign message from user's wallet
        const signature = await signer.signMessage(messageToSign);
        // Using signature generate auth token
        const tokenRes = (
          await axios.post(backendUrl + "/v1/authenticate", {
            walletAddress: address,
            signature,
          })
        ).data;
        if (tokenRes.error || !tokenRes?.data?.token) {
          errorMessage = tokenRes.message;
          throw new Error("12345");
        }
        // Using auth token create payment request on backend and sign the transaction payload
        const authToken = tokenRes?.data?.token;
        // Simulating trx signing
        const trxPayload = await axios.post(
          backendUrl + "/v1/createPaymentRequest",
          {
            rarity: rarity,
            nftAmount: val,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": authToken,
            },
          }
        );
        if (trxPayload.data.error) {
          errorMessage = trxPayload.data.message;
          throw new Error("12345");
        }
        const payload = trxPayload.data.data.trxPayload;
        const paymentRef = trxPayload.data.data.paymentRef;
        // const trx = new ethers.Transaction()
        console.log(payload.to);
        const trx: any = {};
        trx.to = payload.to;
        trx.type = payload.type;
        trx.data = payload.data;
        trx.value = payload.value;
        trx.chainId = payload.chainId;
        trx.nonce = payload.nonce;
        trx.gasLimit = payload.gasLimit;
        trx.maxPriorityFeePerGas = payload.maxPriorityFeePerGas;
        trx.maxFeePerGas = payload.maxFeePerGas;
        const trxRes = await signer.sendTransaction(trx);
        // Submit signed trx
        const submitTransactionRes = (
          await axios.post(
            backendUrl + "/v1/submitTransaction",
            {
              signature: trxRes.hash,
              paymentRef,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "x-access-token": authToken,
              },
            }
          )
        ).data;
        if (submitTransactionRes.error) {
          errorMessage = submitTransactionRes.message;
          throw new Error("12345");
        }
        // Open a new tab to show user's status of transaction
        toast.success(
          "Transaction Submitted!, Your NFTs will soon be minted..."
        );
        setTimeout(() => {
          //       window.open(submitTransactionRes.data.explorerUrl, '_blank')
          //       window.open(
          //         `https://amoy.polygonscan.com/address/${store.walletAddress}#nfttransfers`,
          //         '_blank'
          //       )
          window.location.reload();
        }, 4000);
        putLoaderOnButton(key, false);
      } catch (err: any) {
        putLoaderOnButton(key, false);
        console.error("Error ---- Code ", err);
        if (err.code === "ACTION_REJECTED") {
          toast.error("You rejected request from wallet... Try Again!");
        } else if (err.message == "12345") {
          toast.error(errorMessage);
        } else {
          console.error(err);
          toast.error("Something went wrong. Try Again Later");
        }
      }
    } else if (currentNetwork.title === "Polygon") {
      try {
        // Checking allowance of user against our contract Address
        const usdtContractInstance = await new ethers.Contract(
          contracts.Usdt as string,
          UsdtAbi,
          signer
        );

        let allowance = await usdtContractInstance.allowance(
          address,
          contracts.Kraken
        );
        const decimals = await usdtContractInstance.decimals();
        // allowance = ethers.formatUnits(allowance, decimals)
        allowance = new BigNumber(allowance.toString())
          .div(new BigNumber(10).pow(decimals))
          .toString();
        console.log(decimals, allowance);

        // Check if enough allowance is permitted
        const requiredPrice = Number(val) * Number(price);
        let balanceOf = await usdtContractInstance.balanceOf(address);
        balanceOf = new BigNumber(balanceOf.toString())
          .div(new BigNumber(10).pow(decimals))
          .toString();
        if (Number(balanceOf) < Number(requiredPrice)) {
          putLoaderOnButton(key, false);
          return toast.error("You don't have enough balance to proceed");
        }

        if (Number(allowance) < Number(requiredPrice)) {
          // Approve enough USDT from User's wallet
          let rareTier: any = cards.find((val: any) => val.key === Nft.Rare);
          let maxUSDT: BigNumber = new BigNumber(
            Number(rareTier.price) * 4
          ).multipliedBy(new BigNumber(10).pow(decimals));
          console.log(maxUSDT.toString());
          const approveTokensTrx = await usdtContractInstance.approve(
            contracts.Kraken,
            maxUSDT.toString()
          );
          // Waiting for confirmation and success
          let receiptTrx = await approveTokensTrx.wait(1);

          if (receiptTrx.status === 1) {
            // Call mint function
            console.log("Transaction Successful");
            await mintTokensOnPolygonWithSignature(rarity, key, val);
          }
        }
        // User has already approved enough allowance
        else {
          // proceed with trx
          await mintTokensOnPolygonWithSignature(rarity, key, val);
        }
      } catch (err: any) {
        putLoaderOnButton(key, false);
        if (err.code === "ACTION_REJECTED") {
          toast.error("You rejected request from wallet... Try Again!");
        } else {
          console.error("Error in polygon flow", err);
          toast.error("Something went wrong. Try Again Later");
        }
      }
    }
  };

  const mintFree = async (rarity: string, key: string) => {
    if (currentNetwork.title !== "Polygon") {
      return toast.error(
        "You can claim nfts only on polygon chain. Please switch."
      );
    }
    try {
      putLoaderOnButton(key, true);
      const signature = (
        await axios.post(backendUrl + "/contract/generateSignature", {
          mintToAddress: address,
          whiteListType: "OG",
        })
      ).data;
      const ethProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethProvider.getSigner();

      const contractInstance = new ethers.Contract(
        contracts.Kraken,
        KrakenAbi,
        signer
      );
      const mintTrx = await contractInstance.mintNFTsOGWhitelist(
        signature.data,
        rarity
      );

      toast.info(
        "Transaction submitted to network! Wait for transcation to confirm"
      );
      // window.open(import.meta.env.VITE_POLYGON_EXPLORER_URL + 'tx/' + mintTrx.hash, '_blank')
      const trxReceipt = await mintTrx.wait(1);
      console.log("trxReceipt", trxReceipt);
      toast.success("Minting Free NFT Successful");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (err: any) {
      if (err.code === "ACTION_REJECTED") {
        toast.error("You rejected request from wallet... Try Again!");
        putLoaderOnButton(key, false);
      } else {
        console.error("Error in mintTokensOnPolygonWithSignature", err);
        toast.error("Something went wrong..!");
        putLoaderOnButton(key, false);
      }
    }
  };

  const mintPublically = async (rarity: string, key: string, val: number) => {
    if (currentNetwork.title !== "Polygon") {
      return toast.error(
        "You can claim nfts only on polygon chain. Please switch."
      );
    }
    putLoaderOnButton(key, true);
    try {
      const ethProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethProvider.getSigner();

      const contractInstance = new ethers.Contract(
        contracts.Kraken,
        KrakenAbi,
        signer
      );
      const mintTrx = await contractInstance.mintNFTsWithoutSignature(
        val,
        rarity
      );

      toast.success(
        "Transaction submitted to network! Wait for transcation to confirm"
      );
      // window.open(
      //   import.meta.env.VITE_POLYGON_EXPLORER_URL + 'tx/' + mintTrx.hash,
      //   '_blank'
      // );
      const trxReceipt = await mintTrx.wait(1);
      console.log("trxReceipt", trxReceipt);
      toast.success("Minting Free NFT Successful");
      putLoaderOnButton(key, false);
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (err: any) {
      if (err.code === "ACTION_REJECTED") {
        toast.error("You rejected request from wallet... Try Again!");
        putLoaderOnButton(key, false);
      } else {
        console.error("Error in mintTokensOnPolygonWithSignature", err);
        toast.error("Something went wrong..!");
        putLoaderOnButton(key, false);
      }
    }
  };

  const handleBuy = (key: string, qty: number) => {
    // console.log("buy", key);
    if (!userConnected)
      return document.getElementById("connectWalletBtn")?.click();
    if (!oGWhiteListed && !normalWhiteListed && !publicSaleStarted) {
      return toast.error(
        "Sorry, you are not eligible to mint. Only whitelisted users are allowed at this time"
      );
    } else if (!(Number(userNtfsBalance) < 4)) {
      return toast.error("You have already minted maximum number of NFTs.");
    } else if (!(Number(userNtfsBalance) + qty < 5)) {
      return toast.error("Amount to buy exceed the limit of 4.");
    } else if (
      oGWhiteListed &&
      oGWhitelistMinted &&
      !normalWhiteListed &&
      !publicSaleStarted
    ) {
      return toast.error("You have already minted your free NFT.");
    } else {
      const selectedPack: any = cards.find((obj: any) => obj.key === key);
      // console.log(selectedPack);
      if (publicSaleStarted) {
        mintPublically(selectedPack.rarity, selectedPack.key, qty);
      } else if (oGWhiteListed && !oGWhitelistMinted) {
        mintFree(selectedPack.rarity, selectedPack.key);
      } else {
        mintNFTs(
          selectedPack.rarity,
          selectedPack.key,
          qty,
          selectedPack.price
        );
      }
    }
  };

  return (
    <>
      <section className={styles.store__container}>
        <h1 className={styles.store__title}>{strings.Kbundle_Sale}</h1>
        <div className={styles.saleTimmer}>
        <p>Phase 1 : 30% OFF (Whitelist only)   Sales date : 3 June 2024 Sales time : 12pm UTC Sales duration : 48 hours</p>
          {showSaleStartedTimer ? (
            <>
              Time Left To End Sale:{" "}
              <CountDown
                time={1717761600000}
                completeMsg="Sale Ended"
                onComplete={() => {
                  setShowSaleStartedTimer(true);
                }}
              />
            </>
          ) : (
            <>
              {" "}
              Time Left To Start Sale:{" "}
              <CountDown
                time={1717416000000}
                onComplete={() => {
                  setShowSaleStartedTimer(true);
                }}
              />
            </>
          )}
        </div>
        {/* <h3 className={styles.store__title_secondary}>
          {strings.runePacksSaleDesc}
        </h3> */}

        <div className={styles.store_cards__container}>
          {gettingSaleStartStatus ? (
            <Loader size={100} />
          ) : saleStarted === false ? (
            <h1 className={styles.store__title_secondary}>
              Sale has been ended
            </h1>
          ) : showLoader ? (
            <Loader size={100} />
          ) : cards && cards.length === 0 ? (
            <h3 className={styles.store__title_secondary}>No Data Found</h3>
          ) : (
            cards &&
            cards.map((cardData: any) => {
              return (
                <React.Fragment key={cardData.key}>
                  <Card
                    type={cardData.key}
                    price={cardData.price}
                    buttonOnClick={handleBuy}
                    buttonIsLoading={
                      cardData?.key === Nft.Rare
                        ? buttonLoaders["Rare"]
                        : cardData?.key === Nft.Epic
                        ? buttonLoaders["Epic"]
                        : cardData?.key === Nft.Legendary
                        ? buttonLoaders["Legendary"]
                        : cardData?.key === Nft.Mystic
                        ? buttonLoaders["Mystic"]
                        : false
                    }
                    buttonDisabled={
                      isDisabledButton ||
                      +cardData?.tierSupplyCount -
                        +cardData?.tierMintedCount ===
                        0
                    }
                    buttonClassName={cx(
                      {
                        [styles.buttonPurchase]:
                          +cardData?.tierSupplyCount -
                            +cardData?.tierMintedCount ===
                          0,
                      },
                      styles.buttonPurchaseFontSize
                    )}
                    lostValue={4}
                    maxSupply={cardData?.tierSupplyCount}
                    currentSupply={cardData?.tierMintedCount}
                    isOGWhitelisted={oGMint && !publicSaleStarted}
                    ethPrice={cardData.ethPrice}
                  />
                </React.Fragment>
              );
            })
          )}
        </div>

        <article className={styles.store__info_container}>
          <div className={styles.store_packs__container}>
            Remaining unbought K-Runes after the NFT sale will be
            &nbsp;
            <span className={styles.store__packs_head}>
              burnt and removed from the total supply. &nbsp;
            </span>
            Be sure to get one before it’s gone!
          </div>
          <div className={styles.store__link}>
            <a
              className={styles.joinUsCls}
              target="_blank"
              href="https://discord.com/invite/bountytemple"
            >
              <img className={styles.join_us_image} src={JoinUs} alt="JoinUs" />
            </a>
          </div>
        </article>

        <TraitRewards />
      </section>
    </>
  );
};

export { Store };
