import { put, call } from 'redux-saga/effects';
import { sagaExceptionHandler } from 'utils';
import { apiStopMintingKraken } from 'api';
import {
  RequestStatus,
} from 'types';
import { 
  krakenSetStatus, krakenGetDataDisconnect, krakenStop,
} from 'store/kraken/actionCreators';

export function* krakenStopSaga({ type }: ReturnType<typeof krakenStop>) {
  try{
    yield put(krakenSetStatus({ type, status: RequestStatus.REQUEST }));

    yield call(apiStopMintingKraken);

    yield put(krakenGetDataDisconnect());

    yield put(krakenSetStatus({ type, status: RequestStatus.SUCCESS }));
  } catch (e) {
    sagaExceptionHandler(e);
    yield put(krakenSetStatus({ type, status: RequestStatus.ERROR }));
  }
}
