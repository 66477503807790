// Action types
const SET_MODAL_NETWORK = 'wallet/setModalNetwork';
const SET_ADDRESS = 'wallet/setAddress';
const SET_CHAIN_ID = 'wallet/setChainID';

// Action creators
export const setModalNetwork = (isModalNetwork: boolean) => ({
  type: SET_MODAL_NETWORK,
  payload: isModalNetwork,
});

export const setAddress = (address: string | undefined) => ({
  type: SET_ADDRESS,
  payload: address,
});

export const setChainID = (chainId: string | number | undefined) => ({
  type: SET_CHAIN_ID,
  payload: chainId,
});

// Reducer
const initialState = {
  isModalNetwork: false,
  address: undefined,
  chainId: undefined,
};

const walletReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MODAL_NETWORK:
      return {
        ...state,
        isModalNetwork: action.payload,
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case SET_CHAIN_ID:
      return {
        ...state,
        chainId: action.payload,
      };
    default:
      return state;
  }
};

export default walletReducer;
