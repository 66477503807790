import React, { FC, useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Text, Button } from 'components';
import { pauseIcon, startIcon } from 'assets/images';
// import { krakenStart, krakenStop } from 'store/kraken/actionCreators';
import { krakenSelectors } from 'store/kraken/selectors';
import { toast } from 'react-toastify';
import { getKrakenContractFromProvider } from 'api/krakenContract';
import styles from './styles.module.scss';

type SaleStatusProps = {
  className?: string;
};

export const SaleStatus: FC<SaleStatusProps> = ({ className = '' }) => {
  const { address } = useSelector((state: any) => state.wallet);
  const { provider } = useSelector((state: any) => state.providerReducer);
  const { currentNetwork } = useSelector((state: any) => state.network);

  const isAdmin = useSelector(krakenSelectors.getProp('isAdmin'));

  const [isStarted, setIsStarted] = useState(false);
  const [isStartedLoader, setIsStartedLoader] = useState(false);

  const [isWhite, setIsWhite] = useState(false);
  const [isWhitelistLoader, setIsWhitelistLoader] = useState(false);

  const getKrakenSaleState = async () => {
    const contract = getKrakenContractFromProvider(process.env.REACT_APP_POLYGON_RPC_URL)
    const resSale = await contract.methods.mintingInProgress().call();
    const whiteSale = await contract.methods.whiteListEnabled().call();

    console.log(resSale, whiteSale);
    setIsStarted(resSale);
    setIsWhite(whiteSale);
  };

  useEffect(() => {
    if (isAdmin) getKrakenSaleState();
  }, [isAdmin]);

  const handleStartOrStopSale = async () => {
    if (currentNetwork.title !== 'Polygon') {
      return toast.info('Please switch your chain to Polygon');
    }
    const contract = getKrakenContractFromProvider(provider);
    try {
      setIsStartedLoader(true);
      if (isStarted) {
        await contract.methods.stopMinting().send({ from: address });
      } else {
        await contract.methods.startMinting().send({ from: address });
      }
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsStartedLoader(false);
      getKrakenSaleState();
    }
  };

  const handleStartOrStopWhiteList = async () => {
    if (currentNetwork.title !== 'Polygon') {
      return toast.info('Please switch your chain to Polygon');
    }
    const contract = getKrakenContractFromProvider(provider);
    try {
      setIsWhitelistLoader(true);
      await contract.methods
        .setWhiteListEnabled(!isWhite)
        .send({ from: address });
    } catch (err) {
      toast.error('Something went wrong!');
    } finally {
      setIsWhitelistLoader(false);
      getKrakenSaleState();
    }
  };

  return (
    <>
      <section className={cx(styles.saleStatus, className)}>
        <div>
          <Text className={styles.title}>Sale status</Text>
          <div className={styles.statusContainer}>
            <Text
              className={cx(styles.status, {
                [styles.statusStarted]: isStarted,
                [styles.statusStopped]: !isStarted,
              })}
            >
              {isStarted ? 'Ongoing' : 'Stopped'}
            </Text>
            <Button
              theme={isStarted ? 'outline' : 'round'}
              className={cx(styles.buttonChangeStatus, {
                [styles.buttonStart]: !isStarted,
                [styles.buttonPause]: isStarted,
              })}
              onClick={handleStartOrStopSale}
              isLoading={isStartedLoader}
              disabled={isStartedLoader}
            >
              {isStarted ? (
                'PAUSE'
              ) : (
                <span className={styles.buttonStart}>START</span>
              )}
              <img
                src={isStarted ? pauseIcon : startIcon}
                alt={isStarted ? 'pause' : 'start'}
                className={styles.icon}
              />
            </Button>
          </div>
        </div>
        <div>
          <Text className={styles.title}>Whitelist sale status</Text>
          <div className={styles.statusContainer}>
            <Text
              className={cx(styles.status, {
                [styles.statusStarted]: isWhite,
                [styles.statusStopped]: !isWhite,
              })}
            >
              {isWhite ? 'Ongoing' : 'Stopped'}
            </Text>
            <Button
              theme={isWhite ? 'outline' : 'round'}
              className={cx(styles.buttonChangeStatus, {
                [styles.buttonStart]: !isWhite,
                [styles.buttonPause]: isWhite,
              })}
              onClick={handleStartOrStopWhiteList}
              isLoading={isWhitelistLoader}
              disabled={isWhitelistLoader}
            >
              {isWhite ? (
                'PAUSE'
              ) : (
                <span className={styles.buttonStart}>START</span>
              )}
              <img
                src={isWhite ? pauseIcon : startIcon}
                alt={isWhite ? 'pause' : 'start'}
                className={styles.icon}
              />
            </Button>
          </div>

          <Text className={styles.description}>
            The sale was resumed last time Mar 28 14:50:21 (2 months 5 days ago)
          </Text>
        </div>
      </section>
    </>
  );
};
