import { useCallback } from 'react';
import { metaMask } from './metamask';
import { walletConnectV2 } from './walletConnect';

function useConnector() {
  const connectorType = useCallback(
    (type: string) => (type === 'walletconnect' ? walletConnectV2 : metaMask),
    [],
  );

  return { connectorType };
}

export default useConnector;
