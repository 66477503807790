export enum KrakenActionTypes {
  SetState = 'KRAKEN.SET_STATE',
  SetStatus = 'KRAKEN.SET_STATUS',
  SetStatusMint = 'KRAKEN.SET_STATUS_MINT',
  GetData = 'KRAKEN.GET_DATA',
  GetDataDisconnect = 'KRAKEN.GET_DATA_DISCONNECT',
  Mint = 'KRAKEN.MINT',
  Start = 'KRAKEN.START',
  Stop = 'KRAKEN.STOP',
  AddWhiteList = 'KRAKEN.ADD_WHITELIST',
  RemoveWhiteList = 'KRAKEN.REMOVE_WHITELIST',
  ChangePrice = 'KRAKEN.CHANGE_PRICE',
}
