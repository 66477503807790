/* eslint-disable no-unused-vars */
import React from 'react';
import { setCurrentNetwork } from 'store/web3/selectedNetwork';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { dataSwitchChainMainnet, dataSwitchChainTestnet } from 'components/WalletConnect/constants/chain';

import { setNetworkList } from 'store/web3/selectedNetwork';
const CommonProvider = () => {
  const dispatch = useDispatch();

  const { chainId } = useSelector((state: any) => state.wallet);
  const networksList = useSelector((state: any) => state.network.networksList);

  useEffect(() => {
    if (networksList.length === 0) {
      dispatch(setNetworkList(process.env.REACT_APP_NETWORK_TYPE === 'testnet' ? dataSwitchChainTestnet : dataSwitchChainMainnet));
    }
  }, []);
  useEffect(() => {
    if (chainId && networksList && networksList.length) {
      let currentNetwork = networksList.find((net: any) => net.chainID.toString() === chainId.toString());
      currentNetwork = currentNetwork ? currentNetwork : networksList[0];

      if (currentNetwork) {
        dispatch(setCurrentNetwork(currentNetwork));
      }
    }
  }, [networksList, chainId]);

  return <></>;
};

export default React.memo(CommonProvider);
