/* eslint-disable react/jsx-no-useless-fragment */
import React, { forwardRef, memo } from 'react';
import cx from 'classnames';
import { useHoverEvent } from 'hooks';
import { Loader } from '../Loader';
import {
  ButtonProps,
  ButtonRef,
} from './types';
import styles from './styles.module.scss';

const Button = memo(forwardRef((
  {
    theme = 'primary',
    isBiggerText,
    isFullWidth,
    onClick,
    className,
    children,
    disabled,
    isLoading,
  }: ButtonProps,
  ref: ButtonRef,
) => {
  const { onMouseEnter, onMouseLeave } = useHoverEvent();

  return (
    <button
      ref={ref}
      type="button"
      className={cx(
        styles.button,
        className,
        styles[theme],
        {
          [styles.full_width]: isFullWidth,
          [styles.bigger_text]: isBiggerText,
          [styles.disabled]: disabled,
        },
      )}
      onClick={onClick}
      disabled={disabled || isLoading}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? <Loader /> : <>{children}</>}
    </button>
  );
}));

export { Button };
