import { FC, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import cx from 'classnames';
import { decimalPlaces, strings } from 'appConstants';
import { InputWithButton, Button } from 'components';
import { Nft } from 'types';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { connectWalletBtn, buyBtn, Coin } from 'assets/images/index';

interface CardProps {
  className?: string;
  type: string;
  price: number;
  buttonClassName?: string;
  buttonIsLoading?: boolean;
  buttonDisabled?: boolean;
  buttonOnClick: (key: string, qty: number) => void;
  lostValue: number;
  maxSupply: number;
  currentSupply: number;
  isOGWhitelisted: boolean;
  ethPrice: number
}

export const Card: FC<CardProps> = ({
  className = '',
  type,
  price,
  buttonOnClick,
  buttonIsLoading,
  buttonDisabled,
  lostValue,
  buttonClassName = '',
  maxSupply,
  currentSupply,
  isOGWhitelisted,
  ethPrice
}) => {
  const userConnected = useSelector((state: any) => state.user.userConnected);

  const [value, setValue] = useState<number>(1);

  const handleOnClick = () => {
    buttonOnClick(type, value);
  };

  const handleChangeAmount = (val: number) => {
    setValue(val);
  };

  return (
    <div
      className={cx(
        styles.card,
        // {
        //   [styles.bgRare]: type === Nft.Rare,
        //   [styles.bgEpic]: type === Nft.Epic,
        //   [styles.bgLegendary]: type === Nft.Legendary,
        //   [styles.bgMystic]: type === Nft.Mystic,
        // },
        className
      )}
    >
      <div className={cx(
        styles.packImg,
         {
          [styles.bgRare]: type === Nft.Rare,
          [styles.bgEpic]: type === Nft.Epic,
          [styles.bgLegendary]: type === Nft.Legendary,
          [styles.bgMystic]: type === Nft.Mystic,
        },)}>
      </div>

      <span className={styles.title}>{type} {strings.bundle}</span>
      {/* <span className={styles.description}>
        Minted {currentSupply} out of {maxSupply}
      </span> */}
   
   <span className={styles.price}>
    <img src={Coin} />
        <span className={styles.priceCurrency}>
          {new BigNumber(price)
            .multipliedBy(value)
            .decimalPlaces(decimalPlaces)
            .toString()}{' '}
            USDT
        </span>
    </span>

    <span className={styles.priceEth}> 
      ~ {new BigNumber(ethPrice)
            .multipliedBy(value)
            .decimalPlaces(3)
            .toString()} ETH
    </span>

      <div className={styles["inputContainer"]}>
      {isOGWhitelisted === false && (
        <InputWithButton
          className={styles.input}
          valueDefault={1}
          maxValue={lostValue}
          onChange={handleChangeAmount}
        />
      )}
      </div>
     

      <div className={styles.wrapButton}>
        <Button
          isFullWidth
          className={cx(styles.button, buttonClassName)}
          onClick={handleOnClick}
          isLoading={buttonIsLoading}
          disabled={buttonDisabled}
        >
          {+maxSupply - +currentSupply === 0
            ? 'SOLD OUT'
            : userConnected
            ?  <img src={buyBtn} alt="Buy_Btn"/>
            :  <img src={connectWalletBtn} alt="connectWalletBtn"/>
            }
           
        </Button>
      </div>
    </div>
  );
};
