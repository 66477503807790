import React, { FC, useState, useMemo } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { strings } from 'appConstants';
import { Button, Input, Text } from 'components';
import { getKrakenContractFromProvider } from 'api/krakenContract';
import { Nft, PartialRecord } from 'types';
import { toast } from 'react-toastify';

import styles from './styles.module.scss';

interface AdminInputWithButtonProps {
  className?: string;
}

const AddIpfsUri: FC<AdminInputWithButtonProps> = ({
  className = '',
}: AdminInputWithButtonProps) => {
  const { address } = useSelector((state: any) => state.wallet);
  const { provider } = useSelector((state: any) => state.providerReducer);
  const { currentNetwork } = useSelector((state: any) => state.network);

  const [rareUri, setRareUri] = useState('');
  const [epicUri, setEpicUri] = useState('');
  const [legendaryUri, setLegendaryUri] = useState('');
  const [mysticUri, setMysticUri] = useState('');

  const [uriLoader, setUriLoader] = useState<PartialRecord<Nft, boolean>>({
    Rare: false,
    Mystic: false,
    Legendary: false,
    Epic: false,
  });

  const inputsUris = useMemo(
    () => [
      {
        label: Nft.Rare,
        value: rareUri,
        setValue: setRareUri,
      },
      {
        label: Nft.Epic,
        value: epicUri,
        setValue: setEpicUri,
      },
      {
        label: Nft.Legendary,
        value: legendaryUri,
        setValue: setLegendaryUri,
      },
      {
        label: Nft.Mystic,
        value: mysticUri,
        setValue: setMysticUri,
      },
    ],
    [epicUri, legendaryUri, mysticUri, rareUri]
  );

  const setNewUri = async (packType: Nft) => {
    if (currentNetwork.title !== 'Polygon') {
      return toast.info('Please switch your chain to Polygon');
    }
    const res: PartialRecord<Nft, string> = {};
    if (rareUri) res[Nft.Rare] = rareUri;
    if (epicUri) res[Nft.Epic] = epicUri;
    if (legendaryUri) res[Nft.Legendary] = legendaryUri;
    if (mysticUri) res[Nft.Mystic] = mysticUri;

    const packUri: string | undefined = res[packType];

    if (!packUri?.trim()) return toast.info('Please enter the url.');

    try {
      setUriLoader((prevState) => ({ ...prevState, [packType]: true }));
      const contract = getKrakenContractFromProvider(provider);
      let res;
      if (packType === Nft.Rare) {
        res = await contract.methods
          .setRareURI(packUri)
          .send({ from: address });
      } else if (packType === Nft.Epic) {
        res = await contract.methods
          .setEpicURI(packUri)
          .send({ from: address });
      } else if (packType === Nft.Legendary) {
        res = await contract.methods
          .setLegendaryURI(packUri)
          .send({ from: address });
      } else if (packType === Nft.Mystic) {
        if (process.env.REACT_APP_NETWORK_TYPE === 'testnet')
          res = await contract.methods
            .setMycticURI(packUri)
            .send({ from: address });
        else
          res = await contract.methods
            .setMysticURI(packUri)
            .send({ from: address });
      }
      if (res) {
        toast.success('Uri has been set.');
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong!');
    } finally {
      setUriLoader((prevState) => ({ ...prevState, [packType]: false }));
    }
  };

  return (
    <section className={cx(styles.addIpfs, className)}>
      <div>
        <h2 className={styles.title}>Set IPFS URLs</h2>

        <div className={styles.statusContainer}>
          {inputsUris.map((inputUri, index) => (
            <div key={index} className={styles.typeContainer}>
              <span className={styles.packName}>{inputUri.label}</span>
              <div className={styles.inputContainer}>
                <Input
                  placeholder='https://blue-cheap-skink-291.mypinata.cloud/ipfs/QmeZ57pWJ3DrYdLK67YXuqjjyyVCjm8U2MGU5NzsMaJazs'
                  onChange={inputUri.setValue}
                  value={inputUri.value}
                  className={styles.input__field}
                  key={inputUri.label}
                />
                <Button
                  theme='admin'
                  isFullWidth
                  onClick={() => setNewUri(inputUri.label)}
                  className={styles.input__button}
                  isLoading={uriLoader[inputUri.label]}
                  disabled={uriLoader[inputUri.label] || !inputUri.value}
                >
                  {strings.add}
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { AddIpfsUri };
