export const strings = {
  connectWalletButton: 'Connect Wallet',
  privacyPolicy: 'Privacy Policy | ',
  termsPolicy: 'Terms & Conditions',
  title: 'Avion Finance',
  disconnect: 'Disconnect',

  congratulations: 'Congratulations',
  youHaveMinted: 'you have minted!',
  ok: 'Ok',

  confirmation: 'Confirmation',
  amount: 'Amount',
  cost: 'Cost',
  confirm: 'Confirm',
  cancel: 'Cancel',

  home: 'Home',
  store: 'Store',
  referral: 'Referral',
  admin: 'Admin',

  mintAvionNFT: 'Mint AVION NFT ',
  mint: 'Mint',
  approve: 'Approve BUSD',

  remainig: 'Remaining',
  yourMints: 'Your Mints',
  totalPrice: 'Total Price',
  currentPrice: 'Current Price',

  runePacksSale: 'KRAKEN FOUNDER RUNE NFTS',
  runePacksSaleDesc: 'YOU CAN PURCHASE KRAKEN FOUNDER RUNE NFTs AND EXCHANGE THEM TO THE K-RUNES AFTER THE MARKETPLACE IS LAUNCHED',

  myPacks: 'My packs:',
  infoRules: '*Only 4 Runes packs can be sold to a particular wallet address',
  krakenOpensea: 'Kraken Founder Rune NFTs collection on Opensea',
  add: 'Add',
  remove: 'Remove selected',
  whitelistDesc: 'Multiply addresses separated by comma can be added',
  necessaryValue: 'Please enter all address',

  // modal price configuration
  priceConfiguration: 'Price configuration',
  newPrice: 'New price',
  usdt: 'USDT',
  pack: 'pack',

  copyright: 'Copyright © 2024 bountytemple.com | Orasis Studio SDN. BHD.',
  Kbundle_Sale: 'K-Rune Bundle Sale (Phase 1 : Sale 30%)',
  bundle: "Bundle"

};
