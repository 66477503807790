import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Image } from 'components';
// import { ModalDisconnectWallet } from 'containers';
// import { ModalContext } from 'context';
import { useWallet } from 'hooks';
import { RequestStatus } from 'types';
import { PathName, strings } from 'appConstants';
import { nftSmartLogo } from 'assets/images';
// import { metamaskSelectors } from 'store/metamask/selectors';
import { krakenSelectors } from 'store/kraken/selectors';
import { KrakenActionTypes } from 'store/kraken/actionsTypes';
// import { metamaskConnect } from 'store/metamask/actionCreators';
import { HeaderNavigation } from './HeaderNavigation';
// import { AdminActions } from './AdminActions';
// import { ButtonIconText, UserBlock } from './UserBlock';
import styles from './styles.module.scss';

import MultiWallet from 'components/WalletConnect/MultiWallet'

interface HeaderProps {
  classNameContainer?: string,
  isLoginPath?: boolean, 
}
const Header: FC<HeaderProps> = ({ classNameContainer, isLoginPath }) => {
  // const dispatch = useDispatch();
  
  // const { openModal } = useContext(ModalContext);
  const { isWalletConnected, address, isAdmin } = useWallet();
  // const isLostWallet = useSelector(metamaskSelectors.getProp('isLostWallet'));
  const isAddressInWhitelist = useSelector(krakenSelectors.getProp('isAddressInWhitelist'));
  const statusGetData = useSelector(krakenSelectors.getStatus(KrakenActionTypes.GetDataDisconnect));

  // useEffect(() => {
  //   if (!isLostWallet) {
  //     dispatch(metamaskConnect());
  //   }
  // }, [dispatch, isLostWallet]);
  
  // const onWalletConnectClick = useCallback(() => {
  //   dispatch(metamaskConnect());
  // }, [dispatch]);
  
  // const handleOpenModalDisconnect = useCallback(() => {
  //   openModal(
  //     <ModalDisconnectWallet />,
  //   );
  // }, [openModal]);

  return (
    <header className={cx(
      styles.header__container, 
      { [styles.admin]: isAdmin }, 
      { [styles.login]: isLoginPath }, 
      { [styles.notExistWhitelist]: isWalletConnected && !isAddressInWhitelist }, 
      classNameContainer,
    )}
    >
      <Link
        to={PathName.store}
        className={styles.header__link}
      >
        <Image
          className={styles.header__logo}
          url={nftSmartLogo}
        />
      </Link>

      {/* {!isLoginPath && (
        <HeaderNavigation
          className={styles.header__nav}
          isAdmin={isAdmin}
        />
      )} */}

      {isWalletConnected && !isAddressInWhitelist && statusGetData === RequestStatus.SUCCESS && (
        <span className={styles.textIsNotInWhitelist}>
          Unfortunately, your wallet is not included in the whitelist
          and you are not permitted to purchase the packs.
        </span>
      )}

      {/* {!isAdmin && isWalletConnected &&
        (
          <UserBlock
            classNameContainer={styles.userBlock}
            address={address}
            onExitClick={handleOpenModalDisconnect}
          />
        )}

      {!isAdmin && !isWalletConnected && 
        (
          <ButtonIconText
            isAddressValue={false}
            value={strings.connectWalletButton}
            className={styles.button_icon_text}
            onClick={onWalletConnectClick}
          />
        )} */}
         <MultiWallet />
      
      {/* {isAdmin && (
        <AdminActions />
      )} */}

    </header>
  );
};

export { Header };
