import React, {
  FC, ChangeEvent, useState, useCallback, 
} from 'react';
import cx from 'classnames';
import { ButtonIcon } from 'components';
import { eyeIcon, notEyeIcon } from 'assets/images';
import styles from './styles.module.scss';

interface InputProps {
  className?: string,
  classNameSuffix?: string,
  type?: 'text' | 'number' | 'password',
  name?: string,
  value?: string,
  suffix?: string,
  disabled?: boolean,
  isNumberOnly?: boolean,
  onChange?: (text: string, name?: string) => void,
  placeholder?: string,
}

export const Input: FC<InputProps> = ({
  className = '',
  classNameSuffix = '',
  type = 'text',
  suffix,
  name,
  value,
  disabled,
  isNumberOnly,
  onChange,
  placeholder,
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const pattern = isNumberOnly ? '[0-9]{10}' : undefined;

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event.target.value, name);
  }, [onChange, name]);

  return (
    <div className={cx(styles.wrapInput, className)}>
      <input
        className={cx(styles.input, { [styles.password]: type === 'password' }, className)} 
        type={isShowPassword ? 'text' : type}
        pattern={pattern}
        name={name}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        min="0" 
        step={isNumberOnly ? '1' : undefined}
      />
      {type === 'password' && (
        <ButtonIcon
          imageURL={isShowPassword ? notEyeIcon : eyeIcon}
          className={styles.buttonEye}
          classNameImage={styles.iconEye}
          onClick={() => setIsShowPassword(!isShowPassword)}
        />
      )}
      {suffix && (
      <div
        className={cx(
          styles.input__suffix__box,
          classNameSuffix,
        )}
      >
        {suffix}
      </div>
      )}
    </div>
  );
};
