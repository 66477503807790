import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import { Loader } from 'components/Loader';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';

interface PreloaderProps {
  isLoading: boolean,
  className?: string,
}

const forPortal = (
  <div className={styles.bg}>
    <Loader
      className={styles.loader}
      size={150}
    />
  </div>
);
const Preloader: FC<PropsWithChildren<PreloaderProps>> = ({
  isLoading,
  className,
  children,
}) => (
  <div
    className={cx(
      className,
    )}
  >

    {isLoading && 
      createPortal(forPortal, document.body)}
    { children}
  </div>
);

export { Preloader };
