import React, { FC, useContext, useEffect, useMemo } from 'react';
import { BigNumber } from 'bignumber.js';
import cx from 'classnames';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Text, Button } from 'components';
import { ModalContext } from 'context';
import { runes, decimalPlaces } from 'appConstants';
import { ModalPriceConfiguration } from 'containers/ModalPriceConfiguration';
import { krakenSelectors } from 'store/kraken/selectors';
import { Nft } from 'types';
import { krakenSetState } from 'store/kraken/actionCreators';
import { getKrakenContractFromProvider } from 'api/krakenContract';
import styles from './styles.module.scss';

type SettingsProps = {
  className?: string;
};

export const Settings: FC<SettingsProps> = ({ className = '' }) => {
  const { openModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  const isAdmin = useSelector(krakenSelectors.getProp('isAdmin'));
  const prices = useSelector(krakenSelectors.getProp('prices'), shallowEqual);

  const apiGetNftTiesKraken = async (nftType: Nft): Promise<string> => {
    const contract = getKrakenContractFromProvider(
      process.env.REACT_APP_POLYGON_RPC_URL
    );
    if (nftType === Nft.Epic) {
      return contract.methods.EPIC().call();
    }
    if (nftType === Nft.Legendary) {
      return contract.methods.LEGENDARY().call();
    }
    if (nftType === Nft.Mystic) {
      return contract.methods.MYSTIC().call();
    }
    return contract.methods.RARE().call();
  };

  const apiPricesKraken = async (
    nftType: Nft
  ): Promise<{
    supply: string;
    maxSupply: string;
    price: string;
  }> => {
    const contract = getKrakenContractFromProvider(
      process.env.REACT_APP_POLYGON_RPC_URL
    );
    const tier = await apiGetNftTiesKraken(nftType);
    const res = await contract.methods.tiers(tier).call();
    return {
      ...res,
      price: new BigNumber(res.price.toString())
        .div(new BigNumber(10).pow(6))
        .decimalPlaces(decimalPlaces)
        .toString(),
    };
  };

  const getPrices = async () => {
    const priceObj = {
      [Nft.Rare]: await apiPricesKraken(Nft.Rare),
      [Nft.Epic]: await apiPricesKraken(Nft.Epic),
      [Nft.Legendary]: await apiPricesKraken(Nft.Legendary),
      [Nft.Mystic]: await apiPricesKraken(Nft.Mystic),
    };
    // console.log(priceObj);
    dispatch(
      krakenSetState({
        prices: priceObj,
        isMintingInProgress: true,
        whitelist: [],
      })
    );
  };

  useEffect(() => {
    if (isAdmin) getPrices();
  }, [isAdmin]);

  // console.log(prices);
  const items = useMemo(
    () => [
      {
        name: 'Rare pack:',
        price: `${prices.Rare.price} USDT`,
        pricePerRune: `${new BigNumber(prices.Rare.price)
          .dividedBy(runes.Rare)
          .decimalPlaces(decimalPlaces)
          .toString()} per Rune`,
      },
      {
        name: 'Epic pack:',
        price: `${prices.Epic.price} USDT`,
        pricePerRune: `${new BigNumber(prices.Epic.price)
          .dividedBy(runes.Epic)
          .decimalPlaces(decimalPlaces)
          .toString()} per Rune`,
      },
      {
        name: 'Legendary pack:',
        price: `${prices.Legendary.price} USDT`,
        pricePerRune: `${new BigNumber(prices.Legendary.price)
          .dividedBy(runes.Legendary)
          .decimalPlaces(decimalPlaces)
          .toString()} per Rune`,
      },
      {
        name: 'Mystic pack:',
        price: `${prices.Mystic.price} USDT`,
        pricePerRune: `${new BigNumber(prices.Mystic.price)
          .dividedBy(runes.Mystic)
          .decimalPlaces(decimalPlaces)
          .toString()} per Rune`,
      },
    ],
    [prices]
  );

  const handleOpenModalPriceConfiguration = () => {
    openModal(<ModalPriceConfiguration getPrices={getPrices} />);
  };
  return (
    <section className={cx(styles.settings, className)}>
      <Text className={styles.title}>Price settings</Text>
      {items.map(({ name, price, pricePerRune }) => (
        <div className={styles.item} key={name}>
          <Text className={styles.namePrice}>{name}</Text>
          <Text className={styles.price}>{price}</Text>
          <Text className={styles.pricePerRune}>({pricePerRune})</Text>
        </div>
      ))}
      <Button
        onClick={handleOpenModalPriceConfiguration}
        className={styles.button}
      >
        CONFIGURE
      </Button>
    </section>
  );
};
