import createReducer from 'utils/createReducer';
import { RequestStatus } from 'types';
import { UsdtState } from 'types/store/UsdtState';
import { UsdtActionTypes } from './actionsTypes';
import { usdtHandlers } from './handlers';

export const usdtInitialState: Readonly<UsdtState> = {
  allowanceToKraken: '',
  balance: '',
  ui: {
    [UsdtActionTypes.GetData]: RequestStatus.INIT,
    [UsdtActionTypes.Approve]: RequestStatus.INIT,
  },
};

export default createReducer(usdtInitialState, usdtHandlers);
