import { ethers } from 'ethers';
import Web3 from 'web3';
import { contracts } from 'appConstants';
import { KrakenAbi } from 'assets/abi';

export const getKrakenContract = () => {
  if (window.ethereum === undefined) return false;
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const CONTRACT = new ethers.Contract(contracts.Kraken, KrakenAbi, provider.getSigner());
  if (!CONTRACT) {
    throw new Error('undefined KrakenContract');
  }
  return CONTRACT;
};

export const getKrakenContractFromProvider = (provider: any) => {
  const web3 = new Web3(provider);
  const CONTRACT = new web3.eth.Contract(KrakenAbi as any[], contracts.Kraken);
  return CONTRACT;
};
