import React from 'react';
import { Routes } from 'containers';
import { ModalProvider } from 'context';
import { Web3ReactProvider } from '@web3-react/core';

import {
  hooks as metaMaskHooks,
  metaMask,
} from 'components/WalletConnect/metamask';
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from 'components/WalletConnect/walletConnect';

import CommonProvider from './common-provider';

const connectors: any = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
];
const App = () => (
  <Web3ReactProvider connectors={connectors}>
    <CommonProvider />
    <ModalProvider>
      <Routes />
    </ModalProvider>
  </Web3ReactProvider>
);

export { App };
